import React from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import CorpUsersForm from "@/forms/CorpUsersForm";
import { useCorpUsersStore, useModalStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

export default ({ onCreate = _noop } = {}) => {
  const { ...actions } = useCorpUsersStore();
  const { closeModal } = useModalStore();

  const onSubmit = async ({
    contact,
    corpTitle,
    email,
    level,
    password,
    realname,
    type,
    username,
    useMasking,
    useIndependentPostalCodes,
    editableByWorksheet,
    showRequestedDeliveryTime,
  }) => {
    actions
      .create({
        contact,
        corpTitle,
        email,
        level,
        password,
        realname,
        type,
        username,
        useMasking: useMasking === "true",
        useIndependentPostalCodes: useIndependentPostalCodes === "true",
        editableByWorksheet: editableByWorksheet === "true",
        showRequestedDeliveryTime: showRequestedDeliveryTime === "true",
      })
      .then(() => {
        onCreate();
        closeModal();
      })
      .catch((e) => {
        window.alert(`기업회원 생성에 실패했습니다: ${e.message}`);
      });
  };

  return (
    <Modal size="lg">
      <Modal.Header>
        <Title>기업회원 생성</Title>
      </Modal.Header>
      <Modal.Body>
        <CorpUsersForm onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
};
