import { formatDate, getDateByTimeZone } from "@/lib/date";

import {
  SIZE_TYPE_150_V3,
  SIZE_TYPE_150_V2,
  SIZE_TYPE_200,
} from "@/utils/Print";

import { DELIVERY_TYPE_NORMAL, DELIVERY_TYPE_RETURN } from "@/Models/Delivery";

export const SPOT_CODE_10128 = "10128"; // 두손컴퍼니
export const SPOT_CODE_10129 = "10129"; // 두손컴퍼니
export const SPOT_CODE_10130 = "10130"; // 두손컴퍼니
export const SPOT_CODE_10220 = "10220"; // 두손컴퍼니

export const SPOT_CODE_10191 = "10191"; // 에프엠제이
export const SPOT_CODE_10195 = "10195"; // 에프엠제이
export const SPOT_CODE_10196 = "10196"; // 에프엠제이
export const SPOT_CODE_10197 = "10197"; // 에프엠제이
export const SPOT_CODE_10198 = "10198"; // 에프엠제이

export const SPOT_CODE_10101 = "10101";
export const SPOT_CODE_10111 = "10111"; // 스테이지파이브
export const SPOT_CODE_10170 = "10170"; // 힙핑크
export const SPOT_CODE_10184 = "10184"; // 패션풀
export const SPOT_CODE_10190 = "10190"; // SK텔링크
export const SPOT_CODE_10193 = "10193"; // 배상면주가
export const SPOT_CODE_10209 = "10209"; // 데싱디바
export const SPOT_CODE_10212 = "10212"; // 딜리셔스
export const SPOT_CODE_10240 = "10240"; // 플라이북
export const SPOT_CODE_10241 = "10241"; // LG헬로모바일
export const SPOT_CODE_10276 = "10276"; // 쿠마이츠
export const SPOT_CODE_10279 = "10279"; // 노원 비대면 진약국
export const SPOT_CODE_10282 = "10282"; // 노원구 재택치료 격리관리반
export const SPOT_CODE_10063 = "10063"; // 미디어로그
export const SPOT_CODE_10506 = "10506"; // 컬리
export const SPOT_CODE_10491 = "10491"; // ayoung

export const SPOT_CODE_10480 = "10480"; // 한진
export const SPOT_CODE_10517 = "10517"; // 우리술컴퍼니

export const SPOT_CODE_10546 = "10546"; // 에이치에스에이치

export const SPOT_CODE_10585 = "10585"; // CJ 더마켓

export class DeliveryForWaybill {
  bookId = null;

  corpUser = null;

  customerAddressError = false;

  etc2 = "";
  etc4 = "{}";

  memoFromCustomer = null;

  orderIdFromCorp = null;

  productCount = null;
  productName = null;

  receiptDate = null;

  receiverAddress = null;
  receiverAddressDetail = null;
  receiverAddressRoad = null;
  receiverAllocationGroupName = null;
  receiverDong = null;
  receiverSigungu = null;
  receiverSido = null;
  receiverName = null;
  receiverMobile = null;
  receiverMobile2 = null;

  region = null;

  senderAddress = null;
  senderAddressDetail = null;
  senderAddressRoad = null;
  senderAllocationGroupName = null;
  senderName = null;
  senderMobile = "";

  status = 0;

  spot = null;

  type = DELIVERY_TYPE_NORMAL;

  unrefinedAddress = null;

  constructor(delivery, sizeType) {
    this.bookId = delivery.bookId;

    this.corpUser = delivery.corpUser;

    this.customerAddressError = delivery.customerAddressError;

    this.etc2 = delivery.etc2;
    this.etc4 = delivery.etc4;

    this.memoFromCustomer = delivery.memoFromCustomer;

    this.orderIdFromCorp = delivery.orderIdFromCorp;

    this.productCount = delivery.productCount;
    this.productName = delivery.productName;

    this.receiptDate = delivery.receiptDate;

    this.receiverAddress = delivery.receiverAddress;
    this.receiverAddressDetail = delivery.receiverAddressDetail;
    this.receiverAddressRoad = delivery.receiverAddressRoad;
    this.receiverAllocationGroupName = delivery.receiverAllocationGroupName;
    this.receiverDong = delivery.receiverDongObj;
    this.receiverSigungu =
      (delivery.receiverDongObj && delivery.receiverDongObj.sigungu) || null;
    this.receiverSido =
      (this.receiverSigungu && this.receiverSigungu.sido) || null;
    this.receiverName = delivery.receiverName;
    this.receiverMobile = delivery.receiverMobile;
    this.receiverMobile2 = delivery.receiverMobile2;

    this.region = delivery.region;

    this.senderAddress = delivery.senderAddress;
    this.senderAddressDetail = delivery.senderAddressDetail;
    this.senderAddressRoad = delivery.senderAddressRoad;
    this.senderAllocationGroupName = delivery.senderAllocationGroupName;
    this.senderName = delivery.senderName;
    this.senderMobile = delivery.senderMobile;

    this.status = delivery.status;

    this.sizeType = sizeType;

    this.spot = delivery.spot;

    this.type = delivery.type || DELIVERY_TYPE_NORMAL;

    this.unrefinedAddress = delivery.unrefinedAddress;
  }

  get corpUserEtc() {
    return this.corpUser?.etc;
  }

  get corpUserEtcObj() {
    try {
      return this.corpUserEtc && JSON.parse(this.corpUserEtc);
    } catch (e) {
      return {};
    }
  }

  get useMasking() {
    return !!this.corpUserEtcObj?.useMasking || false;
  }

  get customerAddress() {
    if (this.type === DELIVERY_TYPE_RETURN) {
      return this.returnPickupEtc4Obj
        ? this.returnPickupEtc4Obj.customerAddress &&
          this.returnPickupEtc4Obj.customerAddress !== "undefined"
          ? this.returnPickupEtc4Obj.customerAddress
          : this.senderAddress
        : "";
    } else {
      return this.etc4Obj?.customerAddress || "";
    }
  }

  get customerAddressDetail() {
    if (this.type === DELIVERY_TYPE_RETURN) {
      return this.returnPickupEtc4Obj
        ? this.returnPickupEtc4Obj.customerAddress &&
          this.returnPickupEtc4Obj.customerAddress !== "undefined"
          ? this.returnPickupEtc4Obj.customerAddressDetail
          : this.senderAddressDetail
        : "";

      // return this.returnPickupEtc4Obj
      //   ? this.returnPickupEtc4Obj.customerAddressDetail
      //   : "";
    } else {
      return this.etc4Obj?.customerAddressDetail || "";
    }
  }

  get displayCorpLogoImageObjBySpotCode() {
    switch (this.spotCode) {
      case SPOT_CODE_10191:
      case SPOT_CODE_10193:
      case SPOT_CODE_10195:
      case SPOT_CODE_10196:
      case SPOT_CODE_10197:
      case SPOT_CODE_10198:
        if (this.corpUser && this.corpUser.logoImage) {
          return JSON.parse(this.corpUser.logoImage);
        } else {
          return null;
        }
      default:
        return null;
    }
  }

  get displayOrderIdFromCorpBySpotCode() {
    switch (this.spotCode) {
      case SPOT_CODE_10111:
      case SPOT_CODE_10170:
      case SPOT_CODE_10184:
        return this.orderIdFromCorp;
      default:
        return null;
    }
  }

  get displayReceiverAddressWithoutSigungu() {
    switch (this.spotCode) {
      case SPOT_CODE_10480:
      case SPOT_CODE_10517:
        if (this.status === 9 || !!this.customerAddressError) {
          return "확인 필요";
        } else {
          return this.receiverAddressWithoutSigungu.split(" ")[0] + "****";
        }
      default:
        if (this.useMasking) {
          if (this.status === 9 || !!this.customerAddressError) {
            return "확인 필요";
          } else {
            return this.receiverAddressWithoutSigungu.split(" ")[0] + "****";
          }
        } else {
          return this.receiverAddressWithoutSigungu;
        }
    }
  }

  get displayReceiverAddressRoadWithoutSigungu() {
    switch (this.spotCode) {
      case SPOT_CODE_10480:
      case SPOT_CODE_10517:
        if (this.status === 9 || !!this.customerAddressError) {
          return "확인 필요";
        } else {
          return this.receiverAddressRoadWithoutSigungu;
        }
      default:
        return this.receiverAddressRoadWithoutSigungu;
    }
  }

  get displayReceiverBuilding() {
    switch (this.spotCode) {
      case SPOT_CODE_10480:
      case SPOT_CODE_10517:
      case SPOT_CODE_10546:
        if (this.status === 9 || !!this.customerAddressError) {
          return "";
        } else {
          return "***";
        }
      default:
        if (this.useMasking) {
          if (this.status === 9 || !!this.customerAddressError) {
            return "";
          } else {
            return "***";
          }
        } else {
          return this.receiverBuilding;
        }
    }
  }

  get displayReceiverAddressDetail() {
    if (
      this.receiverAddressDetail &&
      this.receiverAddressDetail.indexOf(this.receiverAddress) > -1
    ) {
      return this.receiverAddressDetail.slice(this.receiverAddress.length);
    } else {
      return this.receiverAddressDetail;
    }
  }

  get displayReceiverMobile() {
    switch (this.sizeType) {
      case SIZE_TYPE_150_V2:
        return `${this.receiverMobile}${this.receiverMobile2 &&
          ` / ${this.receiverMobile2}`}`;
      case SIZE_TYPE_200:
        return `${
          this.receiverMobile
            ? `${this.receiverMobile.slice(
                0,
                3,
              )}****${this.receiverMobile.slice(7)}`
            : ""
        }${
          this.receiverMobile2
            ? ` / ${this.receiverMobile2.slice(
                0,
                3,
              )}****${this.receiverMobile2.slice(7)}`
            : ""
        }`;
      default:
        switch (this.spotCode) {
          case SPOT_CODE_10128:
          case SPOT_CODE_10129:
          case SPOT_CODE_10130:
          case SPOT_CODE_10220:
          case SPOT_CODE_10212:
          case SPOT_CODE_10282:
          case SPOT_CODE_10279:
          case SPOT_CODE_10240:
          case SPOT_CODE_10063:
          case SPOT_CODE_10506:
          case SPOT_CODE_10491:
          case SPOT_CODE_10480:
          case SPOT_CODE_10517:
          case SPOT_CODE_10546:
            return `${
              this.receiverMobile
                ? `${this.receiverMobile.slice(0, -4)}****`
                : ""
            }${
              this.receiverMobile2
                ? ` /${this.receiverMobile2.slice(0, -4)}****`
                : ""
            }`;
          default:
            if (this.useMasking) {
              return `${
                this.receiverMobile
                  ? `${this.receiverMobile.slice(0, -4)}****`
                  : ""
              }${
                this.receiverMobile2
                  ? ` /${this.receiverMobile2.slice(0, -4)}****`
                  : ""
              }`;
            } else {
              return `${this.receiverMobile}${
                this.receiverMobile2 ? ` /${this.receiverMobile2}` : ""
              }`;
            }
        }
    }
  }

  get displayReceiverName() {
    switch (this.sizeType) {
      case SIZE_TYPE_200:
        return `${(this.receiverName || "").slice(
          0,
          1,
        )}*${this.receiverName.slice(2)}`;
      default:
        switch (this.spotCode) {
          case SPOT_CODE_10128:
          case SPOT_CODE_10129:
          case SPOT_CODE_10130:
          case SPOT_CODE_10220:
          case SPOT_CODE_10212:
          case SPOT_CODE_10282:
          case SPOT_CODE_10279:
          case SPOT_CODE_10240:
          case SPOT_CODE_10063:
          case SPOT_CODE_10506:
          case SPOT_CODE_10491:
          case SPOT_CODE_10546:
            return `${(this.receiverName || "").slice(0, -1)}*`;
          case SPOT_CODE_10480:
          case SPOT_CODE_10517:
            return `${(this.receiverName || "").slice(0, 1)}*${(
              this.receiverName || ""
            )
              .slice(2)
              .trim()}`;
          default:
            if (this.useMasking) {
              return `${(this.receiverName || "").slice(0, 1)}*${(
                this.receiverName || ""
              )
                .slice(2)
                .trim()}`;
            } else {
              return this.receiverName;
            }
        }
    }
  }

  get displayUnrefinedAddress() {
    switch (this.spotCode) {
      case SPOT_CODE_10480:
      case SPOT_CODE_10517:
        if (this.status === 9 || !!this.customerAddressError) {
          return `${this.unrefinedAddress ||
            `${this.customerAddress || ""} ${this.customerAddressDetail ||
              ""}`} ${this.etc4Obj.customerAddressEng || ""}`.trim();
        } else {
          return "";
        }

      default:
        return (
          this.unrefinedAddress ||
          `${this.customerAddress || ""} ${this.customerAddressDetail || ""}`
        );
    }
  }

  get displaySenderAddress() {
    switch (this.spotCode) {
      case SPOT_CODE_10480:
      case SPOT_CODE_10517:
      case SPOT_CODE_10546:
        return (
          this.senderAddress
            .split(" ")
            .slice(0, 2)
            .join(" ") + "***"
        );
      default:
        return this.senderAddress;
    }
  }

  get displaySenderAddressDetail() {
    switch (this.spotCode) {
      case SPOT_CODE_10480:
      case SPOT_CODE_10517:
      case SPOT_CODE_10546:
        return "";
      default:
        if (
          this.senderAddressDetail &&
          this.senderAddressDetail.indexOf(this.senderAddress) > -1
        ) {
          return this.senderAddressDetail.slice(this.senderAddress.length);
        } else {
          return this.senderAddressDetail;
        }
    }
  }

  get displaySenderMobile() {
    const senderMobile =
      this.type === DELIVERY_TYPE_NORMAL
        ? this.spotContactForLabel || this.senderMobile
        : this.senderMobile;

    switch (this.sizeType) {
      case SIZE_TYPE_200:
        return `${
          senderMobile
            ? `${senderMobile.slice(0, 3)}****${senderMobile.slice(7)}`
            : ""
        }`;
      default:
        switch (this.spotCode) {
          case SPOT_CODE_10128:
          case SPOT_CODE_10129:
          case SPOT_CODE_10130:
          case SPOT_CODE_10220:
          case SPOT_CODE_10212:
          case SPOT_CODE_10282:
          case SPOT_CODE_10279:
          case SPOT_CODE_10240:
          case SPOT_CODE_10063:
          case SPOT_CODE_10506:
          case SPOT_CODE_10491:
          case SPOT_CODE_10480:
          case SPOT_CODE_10517:
          case SPOT_CODE_10546:
            return `${senderMobile ? `${senderMobile.slice(0, -4)}****` : ""}`;
          default:
            return `${senderMobile}`;
        }
    }
  }

  get displaySenderName() {
    let senderName =
      this.type === DELIVERY_TYPE_NORMAL
        ? this.spotName || this.senderName
        : this.senderName;

    switch (this.spotCode) {
      case SPOT_CODE_10480:
      case SPOT_CODE_10517:
      case SPOT_CODE_10546:
        return `${(senderName || "").slice(0, 1)}*${(senderName || "")
          .slice(2)
          .trim()}`;
      default:
        return senderName;
    }
  }

  get dongCode() {
    switch (this.sizeType) {
      case SIZE_TYPE_150_V3:
        if (this.type === DELIVERY_TYPE_NORMAL) {
          return {
            returnPickup: "",
            sidodong: `${
              this.receiverSidoName ? this.receiverSidoName.slice(0, 2) : ""
            }-${
              this.receiverDongName ? this.receiverDongName.slice(0, 2) : ""
            }-`,
            allocationGroup: this.receiverAllocationGroupName || "(미지정)",
          };
        } else {
          return {
            returnPickup:
              this.type === DELIVERY_TYPE_RETURN
                ? this.returningBack
                  ? "회송-"
                  : "반품-"
                : "픽업-",
            sidodong: "",
            allocationGroup: this.senderAllocationGroupName || "(미지정)",
          };
        }

      case SIZE_TYPE_150_V2:
        if (this.region) {
          return this.region;
        } else {
          return `${this.receiverAllocationGroupName || ""}${
            this.receiverDongName ? this.receiverDongName.slice(0, 2) : ""
          }`;
        }
      case SIZE_TYPE_200:
        if (this.region) {
          return this.region;
        } else {
          return `${this.receiverAllocationGroupName || ""}${
            this.receiverDongName ? this.receiverDongName.slice(0, -1) : ""
          }`;
        }
      default:
        return `${
          this.receiverSidoName ? this.receiverSidoName.slice(0, 2) : ""
        }-${this.receiverAllocationGroupName || "(미지정)"}-${
          this.receiverDongName ? this.receiverDongName.slice(0, 2) : ""
        }`;
    }
  }

  get etc4Obj() {
    try {
      return this.etc4 && JSON.parse(this.etc4);
    } catch (e) {
      return {};
    }
  }

  get firstProductNames() {
    if (this.productObj && this.productObj.productNamesArr.length) {
      return this.productObj.productNamesArr.slice(
        0,
        this.numOfLinesFirstProductNames,
      );
    } else {
      return [];
    }
  }

  get numOfCharactersProductNames() {
    switch (this.sizeType) {
      case SIZE_TYPE_150_V3:
        return 20;
      case SIZE_TYPE_150_V2:
        return 20;
      case SIZE_TYPE_200:
        return 19.5;
      default:
        return 20;
    }
  }

  get numOfLinesRestProductNames() {
    switch (this.sizeType) {
      case SIZE_TYPE_150_V3:
        return 22;
      case SIZE_TYPE_150_V2:
        return 22;
      case SIZE_TYPE_200:
        return 11;
      default:
        return 22;
    }
  }

  get numOfLinesFirstProductNames() {
    switch (this.sizeType) {
      case SIZE_TYPE_150_V3:
        return 18;
      case SIZE_TYPE_150_V2:
        return 18;
      case SIZE_TYPE_200:
        return 11;
      default:
        return 18;
    }
  }

  get productObj() {
    if (this.productName) {
      return splitProductNames(this.productName, {
        lineLength: this.numOfCharactersProductNames,
        postfix: this.postFixBySpotCode,
        targetSymbol: this.targetSymbolBySpotCode,
      });
    } else {
      return null;
    }

    // productNamesArr = splitResult.productNamesArr;
    // gatheredCount = splitResult.productTotalCount;
  }

  get postFixBySpotCode() {
    switch (this.spotCode) {
      case SPOT_CODE_10190:
      case SPOT_CODE_10241:
        return ` (${this.etc2})`;
      default:
        return "";
    }
  }

  get receiverAddressWithoutSigungu() {
    return this.receiverAddress
      ? this.receiverAddress
          .split(" ")
          .slice(2)
          .join(" ")
      : "";
  }

  get receiverAddressRoadWithoutSigungu() {
    return this.receiverAddressRoad
      ? this.receiverAddressRoad
          .split(" ")
          .slice(2)
          .join(" ")
      : "";
  }

  get receiverBuilding() {
    return this.etc4Obj && this.etc4Obj.receiverBuilding;
  }

  get receiverDongName() {
    return this.receiverDong ? this.receiverDong.name : "";
  }

  get receiverSigunguName() {
    return this.receiverSigungu ? this.receiverSigungu.name : "";
  }

  get receiverSidoName() {
    return this.receiverSido ? this.receiverSido.name : "";
  }

  get restProductNames() {
    if (
      this.productObj &&
      this.productObj.productNamesArr.length > this.numOfLinesFirstProductNames
    ) {
      return this.productObj.productNamesArr.slice(
        this.numOfLinesFirstProductNames,
      );
    } else {
      return [];
    }
  }

  get returningBack() {
    if (this.etc4Obj) {
      return !!this.etc4Obj.returningBack;
    } else {
      return false;
    }
  }

  // 반품/픽업의 경우 이전 수하인 주소 표기를 위함
  get returnPickupEtc4Obj() {
    try {
      return this.etc4Obj.etc4 && JSON.parse(this.etc4Obj.etc4);
    } catch (e) {
      return {};
    }
  }

  get senderAddressWithoutSigungu() {
    return this.senderAddress
      ? this.senderAddress
          .split(" ")
          .slice(2)
          .join(" ")
      : "";
  }

  get senderAddressRoadWithoutSigungu() {
    return this.senderAddressRoad
      ? this.senderAddressRoad
          .split(" ")
          .slice(2)
          .join(" ")
      : "";
  }

  get senderBuilding() {
    return this.etc4Obj && this.etc4Obj.senderBuilding;
  }

  get barcodeTitleBySpotCode() {
    switch (this.sizeType) {
      case SIZE_TYPE_150_V3:
      case SIZE_TYPE_150_V2:
        switch (this.spotCode) {
          case SPOT_CODE_10128:
          case SPOT_CODE_10129:
          case SPOT_CODE_10130:
          case SPOT_CODE_10220:
            return "CJ 코드";
          default:
            return null;
        }
      default:
        return null;
    }
  }

  get barcodeCodeBySpotCode() {
    switch (this.sizeType) {
      case SIZE_TYPE_150_V3:
        switch (this.spotCode) {
          case SPOT_CODE_10128:
          case SPOT_CODE_10129:
          case SPOT_CODE_10130:
          case SPOT_CODE_10220:
            return this.etc4Obj && this.etc4Obj.shippingNumber;
          case SPOT_CODE_10209:
          case SPOT_CODE_10276:
            return this.etc4Obj && this.etc4Obj.barcode;
          case SPOT_CODE_10585:
            return this.orderIdFromCorp;
          default:
            return null;
        }
      case SIZE_TYPE_150_V2:
        switch (this.spotCode) {
          case SPOT_CODE_10128:
          case SPOT_CODE_10129:
          case SPOT_CODE_10130:
          case SPOT_CODE_10220:
            return this.etc4Obj && this.etc4Obj.shippingNumber;
          case SPOT_CODE_10209:
            return this.etc4Obj && this.etc4Obj.barcode;
          default:
            return null;
        }
      default:
        return null;
    }
  }

  get spotCode() {
    return this.spot ? this.spot.code : null;
  }

  get spotName() {
    return this.spot ? this.spot.name : null;
  }

  get spotContactForLabel() {
    return this.spot && this.spot.contactForLabel;
  }

  get targetSymbolBySpotCode() {
    switch (this.spotCode) {
      case SPOT_CODE_10101:
        return ":|";
      case SPOT_CODE_10184:
        return "$";
      case SPOT_CODE_10191:
      case SPOT_CODE_10195:
      case SPOT_CODE_10196:
      case SPOT_CODE_10197:
      case SPOT_CODE_10198:
        return ";";
      default:
        return ";;";
    }
  }

  get totalProductPage() {
    return Math.ceil(
      (this.productObj ? this.productObj.productTotalCount : 0) /
        this.numOfLinesRestProductNames,
    );
  }

  renderNumbering(count = 1, totalCount = 1) {
    switch (this.spotCode) {
      case SPOT_CODE_10276:
        return null;
      default:
        return `${count}/${totalCount}`;
    }
  }

  renderTotalProductCount() {
    switch (this.sizeType) {
      case SIZE_TYPE_200:
        return this.productObj ? this.productObj.productTotalCount : 0;
      default:
        return `${this.productObj ? this.productObj.productTotalCount : 0}건${
          this.productCount ? `(${this.productCount}개)` : ""
        }`;
    }
  }

  renderReceiptDate(dateFormat = "yyyy/MM/dd HH:mm") {
    return (
      this.receiptDate &&
      formatDate(getDateByTimeZone(this.receiptDate), dateFormat)
    );
  }
}

const splitProductNames = (
  productNamesStr,
  { lineLength = 21, postfix = "", prefix = "●", targetSymbol = "" },
) => {
  let productTotalCount = 0;
  let productNamesArr = [];

  if (targetSymbol) {
    productNamesArr = productNamesStr
      .split(targetSymbol)
      .filter((p) => !!p.trim());
    productTotalCount = productNamesArr.length;
  } else {
    productNamesArr = [productNamesStr];
    productTotalCount = 1;
  }

  return {
    productNamesArr: productNamesArr
      .filter((p) => p.trim())
      .map((p) => `${prefix}${p}${postfix}`)
      .map((p) => splitProductNameByKr(p, lineLength)),
    productTotalCount,
  };
};

function splitProductNameByKr(productName, length) {
  const krRegex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
  const enCapitalRegex = /[A-Z]/g;

  const etcLength = productName.replace(krRegex, "").replace(enCapitalRegex, "")
    .length;
  const enCapitalLength = productName.replace(krRegex, "").length - etcLength;
  const krLength = productName.length - enCapitalLength - etcLength;

  if (krLength + enCapitalLength * 0.8 + etcLength * 0.6 <= length) {
    return productName;
  }

  const productNames = [];
  let count = 0;
  let splittedProductName = "";
  for (let i = 0; i < productName.length; i++) {
    let nextCount = 0;
    if (krRegex.test(productName[i])) {
      nextCount = 1;
    } else if (enCapitalRegex.test(productName[i])) {
      nextCount = 0.8;
    } else {
      nextCount = 0.6;
    }

    if (count + nextCount > length) {
      productNames.push(splittedProductName);
      splittedProductName = "";
      count = 0;
    } else {
      count += nextCount;
    }

    splittedProductName = `${splittedProductName}${productName[i]}`;

    if (i === productName.length - 1) {
      productNames.push(splittedProductName);
      splittedProductName = "";
      count = 0;
    }
  }

  return productNames;
}
