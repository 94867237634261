import React, { memo, useCallback, useEffect, useRef } from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";
import JsBarcode from "react-barcode";

import { DELIVERY_TYPE_NORMAL } from "@/Models/Delivery";

const PrintContainer = styled.div``;
const WaybillContainer = styled.div``;

/**
 * Style
 */
const PrintContainerStyle = {
  padding: 0,
  margin: 0,
};

const WaybillContainerStyle = {
  position: "relative",
  width: "100mm",
  height: "150mm",
  margin: 0,
  padding: 0,
};

const DefaultStyle = {
  transform: "rotate(-90deg)",
  transformOrigin: "left top",
  position: "absolute",
  fontSize: `11px`,
  whiteSpace: "pre-wrap",
  lineHeight: "3mm",
};

const Barcode = ({ bookId, onLoaded = _noop }) => {
  useEffect(() => {
    onLoaded();
  });

  return (
    <DefaultComponent
      style={{
        top: "140mm",
        left: "75mm",
        width: "60mm",
        height: "12mm",
      }}
    >
      <JsBarcode margin={0} width={1.7} height={40} value={bookId} />
    </DefaultComponent>
  );
};

const BarcodeForSpecificCorpUser = ({ code, title = "" }) => {
  return (
    <DefaultComponent
      style={{
        top: "50mm",
        left: "27mm",
        fontSize: "10px",
        whiteSpace: "pre-wrap",
        lineHeight: "3mm",
      }}
    >
      <div
        style={{
          top: "35mm",
          left: "22mm",
          fontSize: "10px",
          whiteSpace: "pre-wrap",
          lineHeight: "3mm",
          textAlign: "center",
        }}
      >
        <p
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          {title}
        </p>
        <JsBarcode
          fontSize="10"
          margin={0}
          width={1.5}
          height={40}
          value={code}
        />
      </div>
    </DefaultComponent>
  );
};

const DefaultComponent = ({ children, style = {} }) => {
  return <div style={{ ...DefaultStyle, ...style }}>{children}</div>;
};

const DongCode = ({ dongCode }) => {
  return (
    <DefaultComponent
      style={{
        top: `${10 +
          8 * dongCode.returnPickup.length +
          4 * dongCode.sidodong.length +
          8 * dongCode.allocationGroup.length}mm`,
        left: "17mm",
        textAlign: "right",
        letterSpacing: "0.1mm",
      }}
    >
      <span
        style={{
          fontSize: dongCode.returnPickup ? "40px" : "20px",
          fontWeight: "bold",
          whiteSpace: "pre",
        }}
      >
        {dongCode.returnPickup || dongCode.sidodong}
      </span>
      <span
        style={{
          fontSize: "40px",
          fontWeight: "bold",
          whiteSpace: "pre",
        }}
      >
        {dongCode.allocationGroup}
      </span>
    </DefaultComponent>
  );
};

const Etc = memo(({ orderIdFromCorp, logoImage, onAfterRendered = _noop }) => {
  useEffect(() => {
    if (!logoImage) {
      setTimeout(() => {
        onAfterRendered();
      }, 200);
    }
  }, []);

  const etcStyle = {
    top: "87mm",
    left: "5mm",
    fontSize: "12px",
    whiteSpace: "pre",
  };

  if (orderIdFromCorp) {
    return (
      <DefaultComponent style={etcStyle}>
        {orderIdFromCorp.length > 20
          ? `${orderIdFromCorp.slice(0, 20)}\n${orderIdFromCorp.slice(20)}`
          : orderIdFromCorp}
      </DefaultComponent>
    );
  } else if (logoImage) {
    return (
      <DefaultComponent style={etcStyle}>
        <img
          onLoad={onAfterRendered}
          alt="기업 로고 이미지"
          src={logoImage.src}
          style={{
            width: logoImage.width,
            height: logoImage.height,
          }}
        />
      </DefaultComponent>
    );
  } else {
    return null;
  }
});

const Memo = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "145mm",
        left: "65mm",
        width: "55mm",
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "3.5mm",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const OrderIdFromCorp = ({ orderIdFromCorp }) => {
  return (
    <DefaultComponent
      style={{
        top: "77mm",
        left: "25mm",
        fontSize: "10px",
        whiteSpace: "pre-wrap",
        lineHeight: "3mm",
      }}
    >
      {orderIdFromCorp && (
        <div style={{ marginTop: "3px", marginRight: "3px" }}>
          주문번호 {orderIdFromCorp}
        </div>
      )}
    </DefaultComponent>
  );
};

const ProductNames = ({ productNames = [], productCount = null }) => {
  return (
    <DefaultComponent
      style={{
        top: "85mm",
        left: "39mm",
        lineHeight: "3.5mm",
        fontSize: "12px",
        fontWeight: "bold",
        width: "70mm",
        whiteSpace: "pre-wrap",
        wordBreak: "break-all",
      }}
    >
      {productNames.join("\n")}
      {productNames.length === 1 && productCount ? ` [${productCount}개]` : ""}
    </DefaultComponent>
  );
};

const ProductNamesRest = ({ productNames = [] }) => {
  return (
    <DefaultComponent
      style={{
        top: "85mm",
        left: "5mm",
        lineHeight: "3.5mm",
        fontSize: "12px",
        fontWeight: "bold",
        whiteSpace: "pre-wrap",
      }}
    >
      {productNames.join("\n")}
    </DefaultComponent>
  );
};

const ProductTotalCount = ({ totalCount, numbering }) => {
  return (
    <DefaultComponent
      style={{
        top: "77mm",
        left: "31mm",
        fontSize: "10px",
        whiteSpace: "pre-wrap",
      }}
    >
      <div>{totalCount}</div>
      <div>{numbering}</div>
    </DefaultComponent>
  );
};

const ReceiptDate = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "77mm",
        left: "28mm",
        fontSize: "10px",
        whiteSpace: "pre-wrap",
        lineHeight: "3mm",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const ReceiverAddress = ({
  receiverAddressWithoutSigungu,
  receiverAddressRoadWithoutSigungu,
  receiverBuilding,
}) => {
  const _text = `${`${receiverAddressRoadWithoutSigungu} ${receiverBuilding}`.trim()}/${receiverAddressWithoutSigungu}`;

  const text1 = _text.slice(0, 15);
  const text2 = _text.slice(15, 30);
  const text3 = _text.slice(30);

  return (
    <>
      <DefaultComponent
        style={{
          top: "145mm",
          left: "27mm",
          fontSize: "17px",
          fontWeight: "bold",
          lineHeight: "4.1mm",
        }}
      >
        {text1}
      </DefaultComponent>
      <DefaultComponent
        style={{
          top: "145mm",
          left: "32mm",
          fontSize: "17px",
          fontWeight: "bold",
          lineHeight: "4.1mm",
        }}
      >
        {text2}
      </DefaultComponent>
      <DefaultComponent
        style={{
          top: "145mm",
          left: "37mm",
          fontSize: "17px",
          fontWeight: "bold",
          lineHeight: "4.1mm",
        }}
      >
        {text3}
      </DefaultComponent>
    </>
  );
};

const ReceiverNameAndMobile = ({ receiverName, receiverMobile }) => {
  return (
    <DefaultComponent
      style={{
        top: "145mm",
        left: "57mm",
        width: "400px",
      }}
    >
      <div
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          lineHeight: "3mm",
        }}
      >
        {`<${receiverName}>`}
      </div>
      <div
        style={{
          fontSize: "16px",
          marginTop: "4px",
          fontWeight: "bold",
          lineHeight: "3mm",
        }}
      >
        T: {`${receiverMobile}`.trim()}
      </div>
    </DefaultComponent>
  );
};

const RestProductComponets = ({
  numOfLinesRestProductNames,
  restProductNames,
}) => {
  let productNamesComponents = [];
  for (
    let i = 0;
    i < restProductNames.length;
    i += numOfLinesRestProductNames
  ) {
    productNamesComponents.push(
      <WaybillContainer key={i} className="label" style={WaybillContainerStyle}>
        <ProductNamesRest
          productNames={restProductNames.slice(
            i,
            i + numOfLinesRestProductNames,
          )}
        />
        <PrintBreak />
      </WaybillContainer>,
    );
  }

  return productNamesComponents;
};

const SenderName = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "145mm",
        left: "15mm",
        fontSize: "16px",
        fontWeight: "bold",
        width: "100mm",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const SenderAddress = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "145mm",
        left: "19mm",
        fontSize: "11px",
        width: "65mm",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const PrintBreak = () => {
  return <div sytle={{ pageBreakAfter: "alwayws" }}></div>;
};

const UnrefinedAddress = ({ unrefinedAddress }) => {
  const _text = unrefinedAddress ? unrefinedAddress.trim() : "";

  const text1 = _text.slice(0, 15).trim();
  const text2 = _text.slice(15, 30).trim();
  const text3 = _text.slice(30, 45).trim();

  return (
    <>
      <DefaultComponent
        style={{
          top: "145mm",
          left: "42mm",
          width: "55mm",
          fontSize: "14px",
          fontWeight: "bold",
          lineHeight: "4mm",
        }}
      >
        {text1}
      </DefaultComponent>
      <DefaultComponent
        style={{
          top: "145mm",
          left: "45.5mm",
          width: "55mm",
          fontSize: "14px",
          fontWeight: "bold",
          lineHeight: "4mm",
        }}
      >
        {text2}
      </DefaultComponent>
      <DefaultComponent
        style={{
          top: "145mm",
          left: "49mm",
          width: "55mm",
          fontSize: "14px",
          fontWeight: "bold",
          lineHeight: "4mm",
        }}
      >
        {text3}
      </DefaultComponent>
    </>
  );
};

const WaybillWrap = memo(
  ({ delivery, count, totalCount, handleAfterRendered = _noop }) => {
    return (
      <>
        <WaybillContainer className="label" style={WaybillContainerStyle}>
          {/** 운송장 상단 영역 */}
          <Etc
            logoImage={delivery.displayCorpLogoImageObjBySpotCode}
            orderIdFromCorp={delivery.displayOrderIdFromCorpBySpotCode}
            onAfterRendered={handleAfterRendered}
          />

          {/** 운송장 왼쪽 영역 */}
          {delivery.type === DELIVERY_TYPE_NORMAL ? (
            <>
              <SenderName>
                {delivery.displaySenderName} T. {delivery.displaySenderMobile}
              </SenderName>
              <SenderAddress>
                {delivery.displaySenderAddress ||
                  delivery.senderAddressRoad ||
                  ""}
                <br />
                {delivery.displaySenderAddressDetail}
              </SenderAddress>
              <ReceiverAddress
                receiverAddressWithoutSigungu={
                  delivery.displayReceiverAddressWithoutSigungu
                }
                receiverAddressRoadWithoutSigungu={
                  delivery.displayReceiverAddressRoadWithoutSigungu
                }
                receiverBuilding={delivery.displayReceiverBuilding || ""}
              />
              <UnrefinedAddress
                unrefinedAddress={delivery.displayUnrefinedAddress}
              />
              <ReceiverNameAndMobile
                receiverName={delivery.displayReceiverName}
                receiverMobile={delivery.displayReceiverMobile}
              />
            </>
          ) : (
            <>
              <SenderName>
                {delivery.displayReceiverName} T.{" "}
                {delivery.displayReceiverMobile}
              </SenderName>
              <SenderAddress>
                {delivery.receiverAddress || delivery.receiverAddressRoad || ""}
                <br />
                {delivery.displayReceiverAddressDetail}
              </SenderAddress>
              <ReceiverAddress
                receiverAddressWithoutSigungu={
                  delivery.senderAddressWithoutSigungu
                }
                receiverAddressRoadWithoutSigungu={
                  delivery.senderAddressRoadWithoutSigungu
                }
                receiverBuilding={delivery.senderBuilding || ""}
              />
              <UnrefinedAddress
                unrefinedAddress={delivery.displayUnrefinedAddress}
              />
              <ReceiverNameAndMobile
                receiverName={delivery.displaySenderName}
                receiverMobile={delivery.displaySenderMobile}
              />
            </>
          )}

          <Memo>{delivery.memoFromCustomer}</Memo>
          <Barcode bookId={delivery.bookId} />

          {/** 운송장 오른쪽 영역 */}
          <DongCode dongCode={delivery.dongCode} />
          <OrderIdFromCorp orderIdFromCorp={delivery.orderIdFromCorp} />

          <ProductNames
            productNames={delivery.firstProductNames}
            productCount={delivery.productCount}
          />

          <ProductTotalCount
            totalCount={delivery.renderTotalProductCount()}
            numbering={delivery.renderNumbering(count, totalCount)}
          />

          <ReceiptDate>
            {delivery.renderReceiptDate("yyyy/MM/dd HH:mm")}
          </ReceiptDate>

          {delivery.barcodeCodeBySpotCode && (
            <BarcodeForSpecificCorpUser
              code={delivery.barcodeCodeBySpotCode}
              title={delivery.barcodeTitleBySpotCode || null}
            />
          )}

          <PrintBreak />
        </WaybillContainer>

        <RestProductComponets
          numOfLinesRestProductNames={delivery.numOfLinesRestProductNames}
          restProductNames={delivery.restProductNames}
        />
      </>
    );
  },
);

export default function Print100150V3Component({
  deliveries = [],
  onLoaded = _noop,
}) {
  const loadedCount = useRef(0);

  const handleAfterRendered = useCallback(() => {
    loadedCount.current++;
    if (loadedCount.current === deliveries.length) {
      onLoaded();
    }
  }, [loadedCount.current]);

  return (
    <PrintContainer style={PrintContainerStyle}>
      {deliveries.map((d, index) => {
        return (
          <WaybillWrap
            key={index}
            delivery={d}
            count={deliveries.length - index}
            totalCount={deliveries.length}
            handleAfterRendered={handleAfterRendered}
          />
        );
      })}
    </PrintContainer>
  );
}
