import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import _remove from "lodash/remove";
import { Spot } from "@/Models";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  DELETE: "DELETE",
  DELETE_REQUESTED: "DELETE_REQUESTED",
  FETCH_ALL: "FETCH_ALL",
  FETCH_ALL_REQUESTED: "FETCH_ALL_REQUESTED",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_STATE = {
  spots: [],
  spotsRequested: [],
  pageCount: 1,
  query: {
    page: 1,
    pageSize: 10,
    text: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        spots: action.spots,
        pageCount: action.pageCount,
      };
    case CONSTANTS.FETCH_ALL_REQUESTED:
      return {
        ...state,
        spotsRequested: action.spotsRequested,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    case CONSTANTS.DELETE:
      return {
        ...state,
        spots: _remove(state.spots, (spot) => spot.id !== action.id),
      };
    case CONSTANTS.DELETE_REQUESTED:
      return {
        ...state,
        spotsRequested: _remove(
          state.spotsRequested,
          (spot) => spot.key !== action.key,
        ),
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useSpotsStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const addDeliveryRiderOnTheFly = useCallback(
    async (id, riderId) => {
      return await api.post(`/spots/${id}/delivery-riders/${riderId}`);
    },
    [api.post],
  );

  const addPickupRiderOnTheFly = useCallback(
    async (id, riderId, { timeFrom, timeTo }) => {
      return await api.post(`/spots/${id}/pickup-riders/${riderId}`, {
        timeFrom,
        timeTo,
      });
    },
    [api.post],
  );

  const create = useCallback(
    async (data) => {
      return await api.post("/spots", data);
    },
    [api.post],
  );

  const createSpotRequested = useCallback(
    async (key) => {
      return await api.post(`/spots/${key}/requested`);
    },
    [api.post],
  );

  const deleteDeliveryRiderOnTheFly = useCallback(
    (id, riderId) => {
      return api.del(`/spots/${id}/delivery-riders/${riderId}`);
    },
    [api.del],
  );

  const deletePickupRiderOnTheFly = useCallback(
    (id, riderId, data) => {
      return api.del(`/spots/${id}/pickup-riders/${riderId}`, data);
    },
    [api.del],
  );

  const deleteSpot = useCallback(
    async (id) => {
      await api.del(`/spots/${id}`);

      dispatch({
        type: CONSTANTS.DELETE,
        id,
      });
    },
    [api.del, dispatch],
  );

  const deleteSpotRequested = useCallback(
    async (key) => {
      await api.del(`/spots/${key}/requested`);

      dispatch({
        type: CONSTANTS.DELETE_REQUESTED,
        key,
      });
    },
    [api.del, dispatch],
  );

  const fetchAll = useCallback(async () => {
    const query = {
      page: state.query.page,
      pageSize: state.query.pageSize,
      text: state.query.text,
    };

    const { spots, pageCount } = await api.get("/spots", query);

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      spots: spots.map((s) => new Spot(s)),
      pageCount,
    });
  }, [api.get, dispatch, state.query]);

  const fetchAllRequested = useCallback(async () => {
    const query = {
      type: "REQUESTED",
    };

    const response = await api.get("/spots", query);

    dispatch({
      type: CONSTANTS.FETCH_ALL_REQUESTED,
      spotsRequested: response.spots || [],
    });
  }, [api.get, dispatch]);

  const fetchByIdOnTheFly = useCallback(
    async (id) => {
      const response = await api.get(`/spots/${id}`);

      return new Spot(response);
    },
    [api.get],
  );

  const setQuery = useCallback(
    (query) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch, state.query],
  );

  const update = useCallback(
    (id, data) => {
      return api.put(`/spots/${id}`, data);
    },
    [api.put],
  );

  const updateDeliveryHours = useCallback(
    (id, data) => {
      return api.put(`/spots/${id}`, data);
    },
    [api.put],
  );

  const updateByIdOnTheFly = useCallback(
    (id, spot = {}) => {
      return api.put(`/spots/${id}`, spot);
    },
    [api.put],
  );

  const updateOpen = useCallback(
    (id, data) => {
      return api.put(`/spots/${id}/open`, data);
    },
    [api.put],
  );

  return {
    state,
    addDeliveryRiderOnTheFly,
    addPickupRiderOnTheFly,
    create,
    createSpotRequested,
    deleteDeliveryRiderOnTheFly,
    deletePickupRiderOnTheFly,
    deleteSpot,
    deleteSpotRequested,
    fetchAll,
    fetchAllRequested,
    fetchByIdOnTheFly,
    setQuery,
    update,
    updateDeliveryHours,
    updateByIdOnTheFly,
    updateOpen,
  };
};
