import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import _findIndex from "lodash/findIndex";
import dfIsAfter from "date-fns/isAfter";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import XLSX from "xlsx";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import {
  AlertModal,
  DeliveryModal,
  FindAgencyDeliveryModal,
  FileUploadModal,
  FindRiderModal,
  TextInputModal,
  WaybillModal,
} from "@/Modals";
import { DeliveriesStore } from "@/store";
import {
  useDeliveriesStore,
  useDeliveriesKurlyStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const ButtonsCol = styled(Col)`
  margin-left: -2.5px;
  margin-right: -2.5px;

  button {
    margin-left: 2.5px;
    margin-right: 2.5px;
    margin-bottom: 5px;
  }
`;

const ButtonItem = styled(Button)`
  margin-right: 5px;
`;

const CheckBoxRow = styled(Row)`
  margin-top: 5px;
`;

const ColFlex = styled(Col)`
  flex: ${({ flex }) => flex || 1};
  padding-left: 5px;
  padding-right: 5px;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
`;

const CounterCol = styled(Col)`
  display: inline-block;
  margin: 10px 0;
  text-align: right;
`;

const DatePickerCol = styled(Col)`
  padding: 0;
  display: flex;
  flex-direction: row;

  input {
    width: calc(50% - 15px);
  }
`;

const DeliveriesTable = styled(Table)`
  font-size: 14px;
  min-width: 1800px;
`;

const FormLabel = styled(Form.Label)`
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: right;
  font-size: 14px;
`;

const FormRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;

const PaddingCol = styled(Col)`
  padding: 0;
  padding-left: ${(props) => (props.left ? props.left : 0)}px;
`;

const SubmitCol = styled(ColFlex)`
  text-align: right;
`;

const TableRow = styled(Row)`
  overflow: auto;
  flex: 1;
`;
const TableCol = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Wave = styled.span`
  margin: 0 5px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ theme }) => `calc(100vh - ${theme.constants.NAV_HEIGHT})`};
`;

const ClickableColumn = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const BookIdColumn = ({ delivery, onAfterUpdate }) => {
  const { openModal } = useModalStore();

  function handleClick() {
    openModal(
      <DeliveryModal
        backdrop
        key="book-detail-modal"
        bookId={delivery.bookId}
        onAfterUpdate={onAfterUpdate}
        type={delivery.type || "1"}
      />,
    );
  }

  return (
    <ClickableColumn onClick={handleClick}>
      {delivery.renderBookId()}
    </ClickableColumn>
  );
};

const CounterContainer = memo(({ fetch, fetchTime }) => {
  const { loading } = useLoadingStore().state;
  const { openedModal } = useModalStore().state;

  const sec = 1000;
  const [count, setCount] = useState(600 * sec);
  const [pause, setPause] = useState(false);

  const handlePause = useCallback(() => {
    setPause(!pause);
  }, [pause]);

  const counter = useCallback(() => {
    if (count === 0) {
      setCount(600000);

      if (!loading) {
        fetch();
      }
    } else {
      setCount(count - sec);
    }
  }, [count, setCount, loading]);

  useEffect(() => {
    if (!pause && !openedModal) {
      let id = setInterval(counter, sec);
      return () => clearInterval(id);
    }
  }, [pause, openedModal, counter]);

  return (
    <CounterCol>
      {parseInt(count / 60000)}분 {(count % 60000) / 1000}초 남았습니다 (최근
      호출 시간 : {formatDate(getDateByTimeZone(fetchTime), "HH:mm:ss")}){" "}
      <SmallButton onClick={() => handlePause(!pause)} variant="warning">
        {pause || openedModal ? "시작" : "일시정지"}
      </SmallButton>
      <SmallButton onClick={fetch} variant="success">
        새로고침
      </SmallButton>
    </CounterCol>
  );
});

const SearchContainer = memo(() => {
  const { state, ...actions } = useDeliveriesKurlyStore();
  const { closeModal, openModal } = useModalStore();

  const { handleSubmit, register, reset, getValues, setValue } = useForm();

  useEffect(() => {
    reset({
      ...state.query,
    });
  }, []);

  const handleChange = useCallback((e) => {
    if (e.target.name === "dateFrom" && e.target.value > getValues().dateTo) {
      setValue("dateTo", e.target.value);
    }

    if (e.target.name === "dateTo" && e.target.value < getValues().dateFrom) {
      setValue("dateFrom", e.target.value);
    }
  }, []);

  const handleClear = useCallback(() => {
    const query = actions.resetQuery();
    reset({
      ...query,
    });
  }, [actions.resetQuery]);

  const handleSelectRider = useCallback(
    (rider) => {
      closeModal();
      actions.setQuery({
        rider,
      });
    },
    [actions.setQuery, closeModal],
  );

  const handleClickFindRider = useCallback(() => {
    openModal(
      <FindRiderModal onSelect={handleSelectRider} key="find-rider-modal" />,
    );
  }, [handleSelectRider, openModal]);

  const onSubmit = useCallback((data) => {
    actions.setQuery(data);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <ColFlex flex={4}>
          <Row>
            <ColFlex sm={1}>
              <Form.Group>
                <Form.Control as="select" custom name="types" ref={register}>
                  <option value="1;2;3">전체</option>
                  <option value="1">일반</option>
                  <option value="2;3">반품/픽업</option>
                </Form.Control>
              </Form.Group>
            </ColFlex>
            <ColFlex sm={1}>
              <Form.Group>
                <Form.Control as="select" custom name="dateType" ref={register}>
                  <option value={DeliveriesStore.QUERY_DATE_TYPE_RECEIPT_DATE}>
                    접수일자
                  </option>
                  <option
                    value={
                      DeliveriesStore.QUERY_DATE_TYPE_DELIVERY_ALLOCATION_DATE
                    }
                  >
                    배송배차일자
                  </option>
                  <option
                    value={
                      DeliveriesStore.QUERY_DATE_TYPE_PICKUP_COMPLETED_DATE
                    }
                  >
                    수거완료일자
                  </option>
                  <option
                    value={
                      DeliveriesStore.QUERY_DATE_TYPE_DELIVERY_COMPLETED_DATE
                    }
                  >
                    배송완료일자
                  </option>
                </Form.Control>
              </Form.Group>
            </ColFlex>
            <DatePickerCol sm={4}>
              <Form.Control
                id="start"
                name="dateFrom"
                type="date"
                ref={register}
                onChange={handleChange}
              />
              <Wave>~</Wave>
              <Form.Control
                id="end"
                name="dateTo"
                type="date"
                ref={register}
                onChange={handleChange}
              />
            </DatePickerCol>
          </Row>
        </ColFlex>
        <ColFlex>
          <Row>
            <FormLabel column sm={3}>
              라이더
            </FormLabel>
            <PaddingCol left={20}>
              <Form.Group>
                <Form.Control
                  readOnly
                  name="riderName"
                  value={state.query.rider && state.query.rider.name}
                />
              </Form.Group>
            </PaddingCol>
            <PaddingCol sm={3}>
              <Button variant="success" onClick={handleClickFindRider}>
                찾기
              </Button>
            </PaddingCol>
          </Row>
        </ColFlex>
      </FormRow>
      <FormRow>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              송하인명
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="senderName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              송하인연락처
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="senderMobile" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              출발동
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="senderDong" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              수거기사
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="pickupRiderName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              지역선택
            </FormLabel>
            <Col sm={8}>
              <Form.Control as="select" name="region" ref={register} custom>
                <option value="">전체</option>
                <option value="SEOUL">서울</option>
                <option value="GOYANG">고양</option>
                <option value="BUCHEON">부천</option>
                <option value="SEONGNAM">성남</option>
                <option value="HANAM">하남</option>
                <option value="GWANGMYEONG">광명</option>
                <option value="GWACHEON">과천</option>
                <option value="GUNPO">군포</option>
                <option value="SUWON">수원</option>
                <option value="ANSAN">안산</option>
                <option value="ANYANG">안양</option>
                <option value="UIWANG">의왕</option>
                <option value="YONGIN">용인</option>
                <option value="HWASEONG">화성</option>
                <option value="GIMPO">김포</option>
                <option value="GWANGJU">광주</option>
                <option value="INCHEON">인천</option>
                <option value="BUSAN">부산</option>
                <option value="GYUNGNAM_YANGSAN">경남양산</option>
                <option value="ULSAN">울산</option>
                <option value="GYUNGNAM_GIMHAE">경남김해</option>
                <option value="GWANGJU_GWANG">광주광역시</option>
              </Form.Control>
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              지역그룹
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="allocationGroupName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
      </FormRow>
      <FormRow>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              수하인명
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="receiverName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              수하인연락처
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="receiverMobile" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              도착동
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="receiverDong" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              도착도로명
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="receiverAddressRoad" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              배송기사
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="deliveryRiderName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              스캔번호
            </FormLabel>
            <Col sm={8}>
              <Form.Control
                type="number"
                name="orderingNumberByDeliveryRiderId"
                ref={register}
              />
            </Col>
          </Form.Group>
        </ColFlex>
      </FormRow>
      <FormRow>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              예약번호
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="bookId" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              사측주문번호
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="orderIdFromCorp" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex />
        <ColFlex />
        {/* <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              기업회원명
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="corpUserName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              접수점
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="spotName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex> */}
        <ColFlex />
        <SubmitCol>
          <Button variant="danger" onClick={handleClear}>
            초기화
          </Button>{" "}
          <Button variant="success" type="submit">
            조회
          </Button>
        </SubmitCol>
      </FormRow>
    </Form>
  );
});

export default memo(() => {
  const { state, ...actions } = useDeliveriesKurlyStore();
  const { ...deliveriesActions } = useDeliveriesStore();
  const { finishLoading, setLoadingMessage, startLoading } = useLoadingStore();
  const { closeModal, openModal } = useModalStore();
  const { getValues, register, setValue } = useForm();
  const [selectedDeliveries, setSelectedDeliveries] = useState([]);

  const [loaded, setLoaded] = useState(false); // 첫 fetch 진행

  // TODO: 리팩토링 필요
  const handleChangeCheckBoxForm = useCallback(() => {
    let _queryTypes = getValues("queryTypes");

    const includedAll = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ALL,
    );
    const addedAll = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ALL,
    );

    // 전체를 선택한 경우
    // 전체 해제된 경우
    if (_queryTypes.length === 0 || (!includedAll && addedAll)) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_ALL];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    // 미배송
    const addedDelayed = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
    );
    const includedDelayed = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
    );

    if (addedDelayed && !includedDelayed) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedDelayed &&
      includedDelayed &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
      );
    }

    // 픽업 & 반품을 선택한 경우
    const addedTypePickup = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP,
    );
    const includedTypePickup = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP,
    );

    if (!includedTypePickup && addedTypePickup) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedTypePickup &&
      addedTypePickup &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP,
      );
    }

    const addedTypeReturn = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN,
    );
    const includedTypeReturn = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN,
    );

    if (!includedTypeReturn && addedTypeReturn) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedTypeReturn &&
      addedTypeReturn &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN,
      );
    }

    // 우편번호지역
    const addedCustomCustomerPostalCode = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE,
    );
    const includedCustomCustomerPostalCode = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE,
    );

    if (addedCustomCustomerPostalCode && !includedCustomCustomerPostalCode) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedCustomCustomerPostalCode &&
      includedCustomCustomerPostalCode &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE,
      );
    }

    // 취소 or 사고를 선택한 경우
    const addedAccident = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT,
    );
    const includedAccident = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT,
    );

    if (!includedAccident && addedAccident) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAccident &&
      addedAccident &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT,
      );
    }

    const addedCancel = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED,
    );
    const includedCancel = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED,
    );
    if (addedCancel && !includedCancel) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedCancel &&
      includedCancel &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED,
      );
    }

    // 송하인주소오류 / 송하인주소오류취소
    const addedSenderAddedAddressError = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
    );
    const includedSenderAddressError = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
    );
    if (addedSenderAddedAddressError && !includedSenderAddressError) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedSenderAddedAddressError &&
      includedSenderAddressError &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
      );
    }

    const addedSenderAddressErrorCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
    );
    const includedSenderAddressErrorCanceled = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
    );
    if (
      addedSenderAddressErrorCanceled &&
      !includedSenderAddressErrorCanceled
    ) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedSenderAddressErrorCanceled &&
      includedSenderAddressErrorCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
      );
    }

    // 수하인주소오류 / 수하인주소오류취소
    const addedReceiverAddressError = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
    );
    const includedReceiverAddressError = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
    );
    if (addedReceiverAddressError && !includedReceiverAddressError) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedReceiverAddressError &&
      includedReceiverAddressError &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
      );
    }

    const addedReceiverAddressErrorCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
    );
    const includedReceiverAddressErrorCanceled = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
    );
    if (
      addedReceiverAddressErrorCanceled &&
      !includedReceiverAddressErrorCanceled
    ) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedReceiverAddressErrorCanceled &&
      includedReceiverAddressErrorCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
      );
    }

    // 연락처오류 / 연락처오류취소
    const addedMobileError = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR,
    );
    const includedMobileError = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR,
    );
    if (addedMobileError && !includedMobileError) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedMobileError &&
      includedMobileError &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR,
      );
    }

    const addedMobileErrorCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED,
    );
    const includedMobileErrorCanceled = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED,
    );
    if (addedMobileErrorCanceled && !includedMobileErrorCanceled) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedMobileErrorCanceled &&
      includedMobileErrorCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED,
      );
    }

    // 배송 불가 지역을 선택한 경우
    const addedAddressNotSupported = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
    );
    const includedAddressNotSupported = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
    );

    if (!includedAddressNotSupported && addedAddressNotSupported) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAddressNotSupported &&
      addedAddressNotSupported &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
      );
    }

    const addedAddressNotSupportedCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
    );
    const includedAddressNotSupportedCanceled = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
    );

    if (
      !includedAddressNotSupportedCanceled &&
      addedAddressNotSupportedCanceled
    ) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAddressNotSupportedCanceled &&
      addedAddressNotSupportedCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
      );
    }

    // 배달 대행을 선택한 경우
    const addedAgencyCorpUser = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
    );
    const includedAgencyCorpUser = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
    );

    if (!includedAgencyCorpUser && addedAgencyCorpUser) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAgencyCorpUser &&
      addedAgencyCorpUser &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
      );
    }

    let queryTypes = _queryTypes;
    if (queryTypes.indexOf(DeliveriesStore.QUERY_DELIVERY_TYPE_ALL) > -1) {
      const index = _findIndex(
        queryTypes,
        (t) => t === DeliveriesStore.QUERY_DELIVERY_TYPE_ALL,
      );
      queryTypes = [
        ...queryTypes.slice(0, index),
        ...queryTypes.slice(index + 1),
      ];
    }
    queryTypes = queryTypes.filter((t) => !!t);

    setValue("queryTypes", queryTypes);
    actions.setQuery({
      queryTypes,
    });
  }, [actions.setTypesQuery, state.query.queryTypes]);

  const [fetchTime, setFetchTime] = useState();

  const fetch = useCallback(async () => {
    try {
      if (state.query) {
        const includedDelayed = state.query.queryTypes.includes(
          DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
        );

        if (!includedDelayed) {
          startLoading();

          await actions.fetchAll({ query: state.query });
          finishLoading();

          await actions.countByStatus({ query: state.query });

          setFetchTime(new Date());
        } else {
          fetchDelayed();
        }
      }

      setLoaded(true);
    } catch (e) {
      window.alert(
        `배송정보를 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.fetchAll, actions.countByStatus, state.query]);

  const fetchWithoutCounting = useCallback(async () => {
    try {
      if (state.query) {
        const includedDelayed = state.query.queryTypes.includes(
          DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
        );

        if (!includedDelayed) {
          startLoading();

          await actions.fetchAll({ query: state.query });
          finishLoading();

          setFetchTime(new Date());
        } else {
          fetchDelayed();
        }
      }

      setLoaded(true);
    } catch (e) {
      window.alert(
        `배송정보를 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.fetchAll, actions.countByStatus, state.query]);

  const fetchDelayed = useCallback(async () => {
    try {
      if (state.query) {
        startLoading();

        await actions.fetchAllDelayed({ query: state.query });
        finishLoading();

        await actions.countByStatus({ query: state.query });
      }
    } catch (e) {
      window.alert(
        `배송정보를 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.countByStatus, actions.fetchAllDelayed, state.query]);

  useEffect(() => {
    handleClear();
    setValue("queryTypes", state.query.queryTypes);
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  const [columnWidthsObj, setColumnWidthsObj] = useState({});

  useEffect(() => {
    const columnWidths = localStorage.getItem("columnWidths");

    if (columnWidths) {
      setColumnWidthsObj(JSON.parse(columnWidths));
    }
  }, []);

  useEffect(() => {
    if (loaded) {
      if (state.query.page > 1) {
        actions.setQuery({ page: 1 });
      } else {
        fetch();
      }
    }
  }, [
    state.query.allocationGroupName,
    state.query.bookId,
    state.query.corpUserName,
    state.query.dateType,
    state.query.dateFrom,
    state.query.dateTo,
    state.query.orderIdFromCorp,
    state.query.orderingNumberByDeliveryRiderId,
    state.query.receiverAddressRoad,
    state.query.receiverDong,
    state.query.receiverMobile,
    state.query.receiverName,
    state.query.region,
    state.query.rider,
    state.query.pickupRiderName,
    state.query.deliveryRiderName,
    state.query.senderDong,
    state.query.spotName,
    state.query.senderName,
    state.query.senderMobile,
    state.query.types,
  ]);

  useEffect(() => {
    if (loaded) {
      if (state.query.page > 1) {
        actions.setQuery({ page: 1 });
      } else {
        fetchWithoutCounting();
      }
    }
  }, [state.query.queryTypes]);

  useEffect(() => {
    if (loaded) {
      fetch();
    }
  }, [state.query.page, state.query.pageSize, state.query.orderBy]);

  useEffect(() => {
    if (loaded && state.reset) {
      fetch();
      actions.reset(false);
    }
  }, [state.reset]);

  useEffect(() => {
    const changedDateFrom = getDateByTimeZone(state.query.dateFrom);
    const changedDateTo = getDateByTimeZone(state.query.dateTo);
    if (dfIsAfter(changedDateFrom, changedDateTo)) {
      actions.setQuery({
        dateFrom: state.query.dateFrom,
        dateTo: state.query.dateTo,
      });
    }
  }, [state.query.dateFrom, state.query.dateTo]);

  const alertNeedToSelectLeastOne = useCallback(() => {
    window.alert("적어도 1개 이상의 배송을 선택해주세요.");
  }, []);

  const alertNeedToSelectOnlyOne = useCallback(() => {
    window.alert("1개의 배송만 선택해주세요.");
  }, []);

  const handleSelectDeliveryRider = useCallback(
    async (rider) => {
      const totalCount = selectedDeliveries.length;

      if (
        window.confirm(
          `배송배차 ${totalCount}건을 ${rider.name}${
            rider.riderGroup1
              ? `(${rider.riderGroup1.riderGroup2.name}/${rider.riderGroup1.name})`
              : ``
          }에게 지정하시겠습니까?`,
        )
      ) {
        closeModal();

        const requestLength = Math.ceil(totalCount / 10);

        let success = 0;
        let failed = 0;

        startLoading();

        try {
          for (let i = 0; i < requestLength; i++) {
            setLoadingMessage(`${i * 10}/${totalCount}건 배송 배차 진행 중...`);

            const results = await deliveriesActions.allocateDeliveryRiders({
              bookIds: selectedDeliveries
                .slice(i * 10, i * 10 + 10)
                .map((d) => d.bookId),
              riderId: rider.id,
            });

            success += results.success.length;
            failed += results.failed.length;
          }

          finishLoading();

          if (totalCount === failed) {
            window.alert(`배송배차하는데 실패했습니다.`);
          } else {
            alert(
              `배송배차 ${totalCount}건 중 ${success}건을 ${rider.name}${
                rider.riderGroup1
                  ? `(${rider.riderGroup1.riderGroup2.name}/${rider.riderGroup1.name})`
                  : ``
              }에게 지정했습니다\n배송배차건: ${success}, 실패건: ${failed}`,
            );
          }

          fetch();
        } catch (e) {
          finishLoading();
          window.alert(
            `수거 배차 처리 하는데 실패하였습니다.\n에러메시지: ${e.message}`,
          );
        }
      }
    },
    [deliveriesActions.allocateDeliveryRiders, selectedDeliveries],
  );

  const handleSelectPickupRider = useCallback(
    async (rider) => {
      const totalCount = selectedDeliveries.length;

      if (
        window.confirm(
          `수거배차 ${totalCount}건을 ${rider.name}${
            rider.riderGroup1
              ? `(${rider.riderGroup1.riderGroup2.name}/${rider.riderGroup1.name})`
              : ``
          }에게 지정하시겠습니까?`,
        )
      ) {
        closeModal();

        const requestLength = Math.ceil(totalCount / 10);

        let success = 0;
        let failed = 0;

        startLoading();

        try {
          for (let i = 0; i < requestLength; i++) {
            setLoadingMessage(`${i * 10}/${totalCount}건 수거 배차 진행 중...`);

            const results = await deliveriesActions.allocatePickupRiders({
              bookIds: selectedDeliveries
                .slice(i * 10, i * 10 + 10)
                .map((d) => d.bookId),
              riderId: rider.id,
            });

            success += results.success.length;
            failed += results.failed.length;
          }

          finishLoading();

          if (totalCount === failed) {
            window.alert(`수거 배차하는데 실패했습니다.`);
          } else {
            alert(
              `수거배차 ${totalCount}건 중 ${success}건을 ${rider.name}${
                rider.riderGroup1
                  ? `(${rider.riderGroup1.riderGroup2.name}/${rider.riderGroup1.name})`
                  : ``
              }에게 지정했습니다\n수거배차건: ${success}, 실패건: ${failed}`,
            );
          }

          fetch();
        } catch (e) {
          finishLoading();
          window.alert(
            `수거 배차 처리 하는데 실패하였습니다.\n에러메시지: ${e.message}`,
          );
        }
      }
    },
    [deliveriesActions.allocatePickupRiders, selectedDeliveries],
  );

  const handleSubmitAccident = useCallback(
    async ({ text }) => {
      const delivery = selectedDeliveries[0];
      try {
        await deliveriesActions.updateAccident(delivery.bookId, text);
        alert(`${delivery.bookId}건을 사고처리하였습니다.`);
        fetch();
      } catch (e) {
        alert(e.message);
      }
    },
    [fetch, deliveriesActions.updateAccident, selectedDeliveries],
  );

  const handleSubmitCancel = useCallback(
    async ({ text }) => {
      const delivery = selectedDeliveries[0];
      try {
        startLoading();

        await deliveriesActions.updateCancel(delivery.bookId, text);
        alert(`${delivery.bookId}건을 취소처리하였습니다.`);
        fetch();
      } catch (e) {
        alert(e.message);
      }

      finishLoading();
    },
    [fetch, deliveriesActions.updateAccident, selectedDeliveries],
  );

  const handleClear = useCallback(() => {
    actions.resetQuery();
  }, [actions.resetQuery]);

  const handleClickAllocateDeliveryRider = useCallback(() => {
    if (!selectedDeliveries.length) {
      alert("배송배차를 진행할 배송을 선택해주세요.");
      return false;
    }

    openModal(
      <FindRiderModal
        onSelect={handleSelectDeliveryRider}
        key="find-delivery-rider-modal"
      />,
    );
  }, [handleSelectDeliveryRider, openModal, selectedDeliveries]);

  const handleClickAllocatePickupRider = useCallback(() => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    openModal(
      <FindRiderModal
        onSelect={handleSelectPickupRider}
        key="find-pickup-rider-modal"
      />,
    );
  }, [handleSelectPickupRider, openModal, selectedDeliveries]);

  const handleClickAllocatePickupByTypesInBulk = useCallback(async () => {
    if (
      window.confirm(
        "선택된 날짜를 기준으로 픽업/반품건에 대하여 일괄수거배차를 실행하시겠습니까?",
      )
    ) {
      try {
        startLoading();

        const results = await deliveriesActions.allocatePickupByTypesInBulk({
          dateFrom: state.query.dateFrom,
          dateTo: state.query.dateTo,
        });

        if (results.success.length + results.failed.length === 0) {
          window.alert("수거지정 전의 픽업/반품건이 없습니다.");
        } else if (results.success.length === 0) {
          window.alert(
            `픽업/반품건의 일괄수거배차가 실패하였습니다.\n성공건: ${results.success.length}, 실패건: ${results.failed.length}`,
          );
        } else {
          window.alert(
            `픽업/반품건의 일괄수거배차가 실행되었습니다.\n성공건: ${results.success.length}, 실패건: ${results.failed.length}`,
          );
        }
        fetch();
      } catch (e) {
        window.alert(
          `픽업/반품건의 일괄수거배차가 실패하였습니다.\n에러 메시지: ${e.message}`,
        );
      }

      finishLoading();
    }
  }, [deliveriesActions.allocatePickupByTypesInBulk, state.query]);

  const handleClickAccident = useCallback(() => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    if (selectedDeliveries.length > 1) {
      alertNeedToSelectOnlyOne();
      return;
    }

    openModal(
      <TextInputModal
        description={`예약번호 ${selectedDeliveries[0].bookId}건 사고처리 하시겠습니까? 사고처리하시면 해당 건은 더 이상 배송진행을 하실 수 없습니다.`}
        onSubmit={handleSubmitAccident}
        key="accident-modal"
        placeholder="사고 처리 사유"
        title="사고처리"
      />,
    );
  }, [handleSubmitAccident, openModal, selectedDeliveries]);

  const handleClickCancel = useCallback(() => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    if (selectedDeliveries.length > 1) {
      alertNeedToSelectOnlyOne();
      return;
    }

    openModal(
      <TextInputModal
        description={`예약번호 ${selectedDeliveries[0].bookId}건 취소처리 하시겠습니까? 취소처리하시면 해당 건은 더 이상 배송진행을 하실 수 없습니다.`}
        onSubmit={handleSubmitCancel}
        key="cancel-modal"
        placeholder="취소 처리 사유"
        title="취소처리"
      />,
    );
  }, [handleSubmitCancel, openModal, selectedDeliveries]);

  // 대량 취소
  const handleClickCancelBulk = useCallback(() => {
    openModal(
      <FileUploadModal
        dropzoneProps={{
          accept: ".xls, .xlsx",
          onUpload: handleCancelBulkFile,
        }}
        key="cancel-bulk-modal"
        title="엑셀 대량 취소"
      />,
    );
  }, []);

  // 대량 취소(강제)
  const handleClickCancelForcedBulk = useCallback(() => {
    openModal(
      <FileUploadModal
        dropzoneProps={{
          accept: ".xls, .xlsx",
          onUpload: handleCancelForcedBulkFile,
        }}
        key="cancel-bulk-modal"
        title="엑셀 대량 취소(강제)"
      />,
    );
  }, []);

  const handleCancelBulkFile = useCallback(
    async ({ binary }) => {
      const workbook = XLSX.read(binary, {
        type: "binary",
      });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetArr = XLSX.utils.sheet_to_json(worksheet);
      try {
        startLoading();

        const data = worksheetArr.map((h) => {
          const result = {};
          Object.keys(h).forEach((k) => {
            const value = `${h[k]}`.trim();
            if (value) {
              switch (k.replace(/ /g, "")) {
                case "배송번호":
                case "예약번호":
                  result.bookId = value;
                  break;
                default:
                  break;
              }
            }
          });
          return result;
        });

        const response = await deliveriesActions.cancelForcedBulk({
          data,
          forced: false,
        });

        const failds = response.filter((el) => el.status === "rejected");

        let text = `${data.length}건 중 ${data.length -
          failds.length}건을 취소했습니다.\n\n결과 목록`;

        response.map((el, idx) => {
          const _text = `\n${data[idx].bookId}: ${
            el.status === "rejected" ? `실패(${el.reason?.message})` : "성공"
          }`;

          text += _text;
        });

        openModal(
          <AlertModal key="alert-modal" title="대량 취소 결과" text={text} />,
        );

        finishLoading();
        fetch();
      } catch (e) {
        window.alert("대량 취소에 실패했습니다.");

        finishLoading();
        closeModal();
      }
    },
    [deliveriesActions.cancelForcedBulk],
  );

  const handleCancelForcedBulkFile = useCallback(
    async ({ binary }) => {
      const workbook = XLSX.read(binary, {
        type: "binary",
      });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetArr = XLSX.utils.sheet_to_json(worksheet);
      try {
        startLoading();

        const data = worksheetArr.map((h) => {
          const result = {};
          Object.keys(h).forEach((k) => {
            const value = `${h[k]}`.trim();
            if (value) {
              switch (k.replace(/ /g, "")) {
                case "배송번호":
                case "예약번호":
                  result.bookId = value;
                  break;
                default:
                  break;
              }
            }
          });
          return result;
        });

        const response = await deliveriesActions.cancelForcedBulk({
          data,
          forced: true,
        });

        const failds = response.filter((el) => el.status === "rejected");

        let text = `${data.length}건 중 ${data.length -
          failds.length}건을 취소했습니다.\n\n결과 목록`;

        response.map((el, idx) => {
          const _text = `\n${data[idx].bookId}: ${
            el.status === "rejected" ? `실패(${el.reason?.message})` : "성공"
          }`;

          text += _text;
        });

        openModal(
          <AlertModal key="alert-modal" title="대량 취소 결과" text={text} />,
        );

        finishLoading();
        fetch();
      } catch (e) {
        window.alert("대량 취소에 실패했습니다.");

        finishLoading();
        closeModal();
      }
    },
    [deliveriesActions.cancelForcedBulk],
  );

  const handleClickDownload = useCallback(async () => {
    try {
      startLoading();

      const _query = {
        ...state.query,
      };
      await actions.fetchForDownload({ query: _query });
      window.alert(`다운로드 요청을 완료하였습니다.`);
    } catch (e) {
      window.alert(`목록다운에 실패하였습니다.\n에러메시지:${e.message}`);
    }

    finishLoading();
  }, [actions.fetchForDownload, state.query]);

  const handleClickDownloadForBalanceAccount = useCallback(async () => {
    try {
      startLoading();

      const _query = {
        ...state.query,
        queryTypes: [DeliveriesStore.QUERY_BALANCE_ACCOUNT],
      };
      await actions.fetchForDownload({ query: _query });
      window.alert(`정산 다운로드 요청을 완료하였습니다.`);
    } catch (e) {
      window.alert(`정산 다운로드에 실패하였습니다.\n에러메시지:${e.message}`);
    }

    finishLoading();
  }, [actions.fetchForDownload, state.query]);

  const handleClickDownloadForBalanceAccountV2 = useCallback(async () => {
    try {
      startLoading();

      const _query = {
        ...state.query,
        queryTypes: [DeliveriesStore.QUERY_BALANCE_ACCOUNT],
      };

      const {
        sheetUrl,
        totalCount,
        success,
      } = await actions.fetchForDownloadV2({
        query: _query,
      });

      if (success) {
        if (
          window.confirm(
            `정산 다운로드 링크(${sheetUrl})로 이동하시겠습니까? 총 건수: ${totalCount}`,
          )
        ) {
          window.open(sheetUrl);
        }
      } else {
        window.alert(`정산 다운로드에 실패하였습니다.`);
      }
    } catch (e) {
      window.alert(`정산 다운로드에 실패하였습니다.\n에러메시지:${e.message}`);
    }

    finishLoading();
  }, [actions.fetchForDownload, state.query]);

  const handleClickDownloadForDeliveryCompleted = useCallback(async () => {
    try {
      startLoading();

      const _query = {
        ...state.query,
        types: "1",
        queryTypes: ["DELIVERY_COMPLETED_ONLY_FOR_DOWNLOAD"],
      };

      await actions.fetchForDownload({ query: _query });
      window.alert(`다운로드 요청을 완료하였습니다.`);
    } catch (e) {
      window.alert(`목록다운에 실패하였습니다.\n에러메시지:${e.message}`);
    }

    finishLoading();
  }, [actions.fetchForDownload, state.query]);

  const handleClickDownloadForPickupCompleted = useCallback(async () => {
    try {
      startLoading();

      const _query = {
        ...state.query,
        types: "2;3",
        queryTypes: ["PICKUP_COMPLETED_ONLY_FOR_DOWNLOAD"],
      };

      await actions.fetchForDownload({ query: _query });
      window.alert(`다운로드 요청을 완료하였습니다.`);
    } catch (e) {
      window.alert(`목록다운에 실패하였습니다.\n에러메시지:${e.message}`);
    }

    finishLoading();
  }, [actions.fetchForDownload, state.query]);

  const handleClickRestoreAccident = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    const impossible = selectedDeliveries.some(
      (d) => !d.isPossibleToRestoreAccident,
    );

    if (impossible) {
      window.alert("사고해제 할 수 없는 목록이 있습니다. 다시 확인해주십시오.");
      return;
    }

    const firstDeliveryBookId = selectedDeliveries[0].bookId;
    const totalCount = selectedDeliveries.length;

    if (
      window.confirm(
        `예약번호 ${firstDeliveryBookId} 포함 ${totalCount}건에 대해서 사고해제 처리하시겠습니까?`,
      )
    ) {
      try {
        startLoading();
        await deliveriesActions.restoreAccident(
          selectedDeliveries.map((d) => d.bookId),
        );
        window.alert(
          `예약번호 ${firstDeliveryBookId}건 포함 ${totalCount}건에 대해서 사고해제 처리하였습니다.`,
        );
        fetch();
      } catch (e) {
        window.alert(
          `사고해제 처리하는데 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }

      finishLoading();
    }
  }, [deliveriesActions.restoreAccident, fetch, selectedDeliveries]);

  const handleClickRestoreCancel = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    const impossible = selectedDeliveries.some(
      (d) => !d.isPossibleToRestoreCancel,
    );

    if (impossible) {
      window.alert("취소해제 할 수 없는 목록이 있습니다. 다시 확인해주십시오.");
      return;
    }

    const firstDeliveryBookId = selectedDeliveries[0].bookId;
    const totalCount = selectedDeliveries.length;

    if (
      window.confirm(
        `예약번호 ${firstDeliveryBookId} 포함 ${totalCount}건에 대해서 취소해제 처리하시겠습니까?`,
      )
    ) {
      try {
        startLoading();

        await deliveriesActions.restoreCancel(
          selectedDeliveries.map((d) => d.bookId),
        );
        window.alert(
          `예약번호 ${firstDeliveryBookId}건 포함 ${totalCount}건에 대해서 취소해제 처리하였습니다.`,
        );
        fetch();
      } catch (e) {
        window.alert(
          `취소해제 처리하는데 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }

      finishLoading();
    }
  }, [deliveriesActions.restoreCancel, selectedDeliveries]);

  const handleSubmitSetDeliveryComplete = useCallback(
    async ({ text }) => {
      if (!selectedDeliveries.length) {
        alertNeedToSelectLeastOne();
        return;
      }

      const firstDeliveryBookId = selectedDeliveries[0].bookId;
      const totalCount = selectedDeliveries.length;

      if (
        window.confirm(
          `예약번호 ${firstDeliveryBookId} 포함 ${totalCount}건에 대해서 배송완료하시겠습니까?`,
        )
      ) {
        try {
          startLoading();
          const {
            success,
            failed,
          } = await deliveriesActions.setDeliveryCompletedStatus(
            selectedDeliveries.map((d) => d.bookId),
            text,
          );
          finishLoading();

          window.alert(
            `예약번호 ${totalCount}건 중 ${success.length}건에 대해서 배송완료했습니다.\n성공건: ${success.length}, 실패건: ${failed.length}`,
          );
          closeModal();
          fetch();
        } catch (e) {
          window.alert(
            `배송완료하는데 실패하였습니다.\n에러메시지: ${e.message}`,
          );
        }
      }
    },
    [deliveriesActions.setDeliveryCompletedStatus, selectedDeliveries],
  );

  const handleClickSetDeliveryCompletedStatus = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    const impossible = selectedDeliveries.some(
      (d) => !d.isPossibleToSetDeliveryCompletedStatus,
    );

    if (impossible) {
      window.alert(
        "배송완료상태로 변경할 수 없는 목록이 있습니다. 다시 확인해주십시오.",
      );
      return;
    }

    const firstDeliveryBookId = selectedDeliveries[0].bookId;
    const totalCount = selectedDeliveries.length;

    openModal(
      <TextInputModal
        description={`예약번호${firstDeliveryBookId}건 포함 ${totalCount} 건을 배송완료 처리하겠습니까? 관제 배송완료 시 배송완료 사유를 기록해주십시오.`}
        key="set-delivery-complete"
        onSubmit={handleSubmitSetDeliveryComplete}
        placeholder="배송완료 사유"
        title="배송완료 처리"
      />,
    );
  }, [deliveriesActions.setDeliveryCompletedStatus, selectedDeliveries]);

  // const handleClickSetDeliveryStartedAll = useCallback(async () => {
  //   if (window.confirm("일괄배송출발 하시겠습니까?")) {
  //     try {
  //       startLoading();
  //       await deliveriesActions.setDeliveryStartedAll();
  //       window.alert("일괄배송출발되었습니다.");
  //       fetch();
  //     } catch (e) {
  //       window.alert("일괄배송출발에 실패하였습니다.");
  //     }

  //     finishLoading();
  //   }
  // }, [deliveriesActions.setDeliveryStartedAll]);

  const handleClickSetDeliveryStartedInBulk = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    const impossible = selectedDeliveries.some(
      (d) => !d.isPossibleToSetDeliveryStarted,
    );

    if (impossible) {
      window.alert(
        "배송출발 상태로 변경할 수 없는 목록이 있습니다. 다시 확인해주십시오.",
      );
      return;
    }

    const firstDeliveryBookId = selectedDeliveries[0].bookId;
    const totalCount = selectedDeliveries.length;

    if (
      window.confirm(
        `예약번호 ${firstDeliveryBookId} 포함 ${totalCount}건에 대해서 배송출발 상태로 변경 처리하시겠습니까?`,
      )
    ) {
      try {
        startLoading();

        const response = await deliveriesActions.setDeliveryStartedInBulk(
          selectedDeliveries,
        );

        const failds = response.filter((el) => el.status === "rejected");

        let text = `${
          selectedDeliveries.length
        }건 중 ${selectedDeliveries.length -
          failds.length}건에 대해서 배송출발 상태로 변경했습니다.\n\n결과 목록`;

        response.map((el, idx) => {
          const _text = `\n${selectedDeliveries[idx].bookId}: ${
            el.status === "rejected" ? `실패(${el.reason?.message})` : "성공"
          }`;

          text += _text;
        });

        window.alert(text);

        finishLoading();
        fetch();

        fetch();
      } catch (e) {
        finishLoading();
        window.alert(
          `배송출발 상태로 변경하는데 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    }
  }, [deliveriesActions.setDeliveryStartedInBulk, selectedDeliveries]);

  const handleClickSetPreviousStatus = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    const impossible = selectedDeliveries.some(
      (d) => !d.isPossibleToSetPreviousStatus,
    );

    if (impossible) {
      window.alert(
        "이전단계로 변경할 수 없는 목록이 있습니다. 다시 확인해주십시오.",
      );
      return;
    }

    const firstDeliveryBookId = selectedDeliveries[0].bookId;
    const totalCount = selectedDeliveries.length;

    if (
      window.confirm(
        `예약번호 ${firstDeliveryBookId} 포함 ${totalCount}건에 대해서 이전 단계로 변경 처리하시겠습니까?`,
      )
    ) {
      const requestLength = Math.ceil(totalCount / 10);

      let success = 0;
      let failed = 0;

      startLoading();

      try {
        for (let i = 0; i < requestLength; i++) {
          setLoadingMessage(
            `${i * 10}/${totalCount}건 이전 단계로 변경 진행 중...`,
          );

          const results = await deliveriesActions.setPreviousStatus(
            selectedDeliveries.slice(i * 10, i * 10 + 10).map((d) => d.bookId),
          );

          success += results.success.length;
          failed += results.failed.length;
        }

        finishLoading();

        if (totalCount === failed) {
          window.alert(`이전 단계로 변경하는데 실패했습니다.`);
        } else {
          window.alert(
            `${totalCount}건 중 ${success}건에 대해서 이전 단계 상태로 변경했습니다.\n성공건: ${success}, 실패건: ${failed}`,
          );
        }

        fetch();
      } catch (e) {
        finishLoading();
        window.alert(
          `이전단계로 변경하는데 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    }
  }, [deliveriesActions.setPreviousStatus, selectedDeliveries]);

  const handleClickSetWarehousedStatus = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    const impossible = selectedDeliveries.some(
      (d) => !d.isPossibleToSetWarehousedStatus,
    );

    if (impossible) {
      window.alert(
        "입고상태로 변경할 수 없는 목록이 있습니다. 다시 확인해주십시오.",
      );
      return;
    }

    const firstDeliveryBookId = selectedDeliveries[0].bookId;
    const totalCount = selectedDeliveries.length;

    if (
      window.confirm(
        `예약번호 ${firstDeliveryBookId} 포함 ${totalCount}건에 대해서 입고상태로 변경 처리하시겠습니까?`,
      )
    ) {
      let allocated = 0;
      let notAllocated = 0;
      let failed = 0;

      startLoading();

      try {
        const results = await actions.setWarehousedStatusBulk(
          selectedDeliveries,
        );

        allocated += results.filter(
          (el) => el.status === "fulfilled" && el.value?.allocated,
        ).length;
        notAllocated += results.filter(
          (el) => el.status === "fulfilled" && !el.value?.allocated,
        ).length;
        failed += results.filter((el) => el.status !== "fulfilled").length;

        finishLoading();

        if (totalCount === failed) {
          window.alert(`입고에 실패했습니다.`);
        } else {
          window.alert(
            `${totalCount}건 중 ${allocated +
              notAllocated}건에 대해서 입고상태로 변경했습니다.\n자동배차건: ${allocated}, 단순입고건: ${notAllocated}, 실패건: ${failed}`,
          );
        }

        fetch();
      } catch (e) {
        finishLoading();
        window.alert(
          `입고상태로 변경하는데 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    }
  }, [deliveriesActions.setWarehousedStatusBulk, selectedDeliveries]);

  const handleClickSetWarehousedStatusBeforePickup = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    const impossible = selectedDeliveries.some(
      (d) => !d.isPossibleToSetWarehousedStatusBeforePickup,
    );

    if (impossible) {
      window.alert(
        "미수거입고 처리할 수 없는 목록이 있습니다. 다시 확인해주십시오.",
      );
      return;
    }

    const firstDeliveryBookId = selectedDeliveries[0].bookId;
    const totalCount = selectedDeliveries.length;

    if (
      window.confirm(
        `예약번호 ${firstDeliveryBookId} 포함 ${totalCount}건에 대해서 미수거입고 처리하시겠습니까?`,
      )
    ) {
      let allocated = 0;
      let notAllocated = 0;
      let failed = 0;

      startLoading();

      try {
        const results = await deliveriesActions.setWarehousedStatusBeforePickupBulk(
          selectedDeliveries,
        );

        allocated += results.filter((el) => el.success && el.allocated).length;
        notAllocated += results.filter((el) => el.success && !el.allocated)
          .length;
        failed += results.filter((el) => !el.success).length;

        finishLoading();

        if (totalCount === failed) {
          window.alert(`미수거입고에 실패했습니다.`);
        } else {
          window.alert(
            `${totalCount}건 중 ${allocated +
              notAllocated}건에 대해서 미수거입고상태로 변경했습니다.\n자동배차건: ${allocated}, 단순입고건: ${notAllocated}, 실패건: ${failed}`,
          );
        }

        fetch();
      } catch (e) {
        finishLoading();
        window.alert(
          `미수거입고 처리하는데 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    }
  }, [
    deliveriesActions.setWarehousedStatusBeforePickupBulk,
    selectedDeliveries,
  ]);

  const handleClickRefineAddresses = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    if (
      window.confirm(
        `${selectedDeliveries.length} 건을 주소정제 처리하시겠습니까?`,
      )
    ) {
      try {
        startLoading();

        let refineDeliveries = [];

        selectedDeliveries.forEach((el) => {
          refineDeliveries.push({
            bookId: el.bookId,
            address: el.unrefinedAddress || el.customerAddress,
            addressDetail: el.unrefinedAddress
              ? null
              : el.customerAddressDetail,
            etc4: el.etc4,
          });
        });

        const result = await deliveriesActions.setRefineAddresses(
          refineDeliveries,
        );

        const successCount = result.filter((el) => el.success).length;

        window.alert(
          `${
            refineDeliveries.length
          }건 중 ${successCount}건 주소정제를 완료했습니다.(실패 ${refineDeliveries.length -
            successCount}건)`,
        );

        fetch();
      } catch (e) {
        window.alert(
          `주소정제 처리하는데 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }

      finishLoading();
    }
  }, [deliveriesActions.setRefineAddresses, selectedDeliveries]);

  const handleClickWaybill = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    try {
      startLoading();

      const waybillDeliveries = await deliveriesActions.fetchAllSelectedDeliveries(
        selectedDeliveries,
      );

      openModal(
        <WaybillModal key="waybill-modal" deliveries={waybillDeliveries} />,
      );
    } catch (e) {
      window.alert(
        `운송장 출력을 위한 배송목록 조회에 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [deliveriesActions.fetchAllSelectedDeliveries, selectedDeliveries]);

  const handleAfterDetailUpdate = useCallback(() => {
    fetch();
  }, [fetch]);

  const handleClickSyncKoomin = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    const impossible = selectedDeliveries.some(
      (d) => !d.isPossibleToUpdateKookmin,
    );

    if (impossible) {
      window.alert(
        "배달 업데이트 할 수 없는 목록이 있습니다. 다시 확인해주십시오.",
      );
      return;
    }

    const firstDeliveryBookId = selectedDeliveries[0].bookId;
    const totalCount = selectedDeliveries.length;

    if (
      window.confirm(
        `예약번호 ${firstDeliveryBookId} 포함 ${totalCount}건에 대해서 배달 업데이트 처리하시겠습니까?`,
      )
    ) {
      try {
        const response = await deliveriesActions.syncAgencyBulk({
          deliveries: selectedDeliveries,
        });

        const failds = response.filter((el) => el.status === "rejected");

        let text = `${
          selectedDeliveries.length
        }건 중 ${selectedDeliveries.length -
          failds.length}건을 배달 업데이트했습니다.\n\n결과 목록`;

        response.map((el, idx) => {
          const _text = `\n${selectedDeliveries[idx].bookId}: ${
            el.status === "rejected" ? `실패(${el.reason?.message})` : "성공"
          }`;

          text += _text;
        });

        window.alert(text);

        fetch();
      } catch (e) {
        window.alert(
          `배달 업데이트 처리하는데 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    }
  }, [selectedDeliveries, deliveriesActions.syncAgencyBulk]);

  const handleClickFindAgencyDelivery = useCallback(() => {
    openModal(<FindAgencyDeliveryModal key="find-agency-delivery-modal" />);
  }, []);

  const handleClickHeader = useCallback(
    (orderBy) => {
      actions.setQuery({ orderBy });
    },
    [actions.setQuery],
  );

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const handleSelect = useCallback(
    ({ selectedRows }) => {
      setSelectedDeliveries(selectedRows);
    },
    [setSelectedDeliveries],
  );

  const columns = useMemo(
    () => [
      {
        Header: "예약번호",
        id: "bookId",
        accessor: (row) => (
          <BookIdColumn
            delivery={row}
            onAfterUpdate={handleAfterDetailUpdate}
          />
        ),
        selectable: false,
        sortable: true,
        width: columnWidthsObj.bookId || 120,
      },
      {
        Header: "접수일시",
        accessor: (row) => row.renderReceiptDate(),
        id: "RECEIPT_DATE",
        sortable: true,
        width: columnWidthsObj.RECEIPT_DATE || 100,
      },
      {
        Header: "수지",
        accessor: (row) => row.renderPickupDateScheduled("HH:mm"),
        id: "PICKUP_SCHEDULED_DATE",
        sortable: true,
        width: columnWidthsObj.PICKUP_SCHEDULED_DATE || 60,
      },
      {
        Header: "수완",
        accessor: (row) => row.renderPickupDateCompleted("HH:mm"),
        id: "PICKUP_COMPLETED",
        sortable: true,
        width: columnWidthsObj.PICKUP_COMPLETED || 60,
      },
      {
        Header: "입고",
        accessor: (row) => row.renderWarehousedAt("HH:mm"),
        id: "WAREHOUSED",
        sortable: true,
        width: columnWidthsObj.WAREHOUSED || 60,
      },
      {
        Header: "배차",
        accessor: (row) => row.renderDeliveryAllocatedDate(),
        id: "DELIVERY_ALLOCATED",
        sortable: true,
        width: columnWidthsObj.DELIVERY_ALLOCATED || 90,
      },
      {
        Header: "연기",
        accessor: (row) => row.renderPostponedDate(),
        id: "DELIVERY_WAITING",
        sortable: true,
        width: columnWidthsObj.DELIVERY_WAITING || 90,
      },
      {
        Header: "배출",
        accessor: (row) => row.renderReleasedAt(),
        id: "DELIVERY_STARTED",
        sortable: true,
        width: columnWidthsObj.DELIVERY_STARTED || 90,
      },
      {
        Header: "배완",
        accessor: (row) => row.renderDeliveryCompletedDate(),
        id: "DELIVERY_COMPLETED",
        sortable: true,
        width: columnWidthsObj.DELIVERY_COMPLETED || 90,
      },
      {
        Header: "접수점",
        accessor: (row) => row.spotName,
        id: "SPOT_NAME",
        sortable: true,
        width: columnWidthsObj.SPOT_NAME || 150,
      },
      {
        Header: "출발동",
        accessor: (row) => row.senderDongName,
        id: "SENDER_DONG",
        sortable: true,
        width: columnWidthsObj.SENDER_DONG || 90,
      },
      {
        Header: "송하인",
        accessor: (row) => row.senderName,
        id: "SENDER_NAME",
        sortable: true,
        width: columnWidthsObj.SENDER_NAME || 150,
      },
      {
        Header: "지역",
        accessor: (row) =>
          `${row.receiverSidoName} ${row.receiverSigunguName}`.trim(),
        id: "REGION",
        sortable: true,
        width: columnWidthsObj.REGION || 150,
      },
      {
        Header: "도착동",
        accessor: (row) => row.receiverDongName,
        id: "RECEIVER_DONG",
        sortable: true,
        width: columnWidthsObj.RECEIVER_DONG || 90,
      },
      {
        Header: "도로명",
        accessor: (row) => row.receiverAddressRoadWithoutSigungu,
        id: "RECEIVER_ADDRESS_ROAD",
        selectable: false,
        sortable: true,
        width: columnWidthsObj.RECEIVER_ADDRESS_ROAD || 150,
      },
      {
        Header: "수거 담당",
        accessor: (row) => row.pickupRiderName,
        id: "PICKUP_RIDER_NAME",
        sortable: true,
        width: columnWidthsObj.PICKUP_RIDER_NAME || 80,
      },
      {
        Header: "배송 담당",
        accessor: (row) => row.deliveryRiderName,
        id: "DELIVERY_RIDER_NAME",
        sortable: true,
        width: columnWidthsObj.DELIVERY_RIDER_NAME || 80,
      },
    ],
    [handleAfterDetailUpdate, columnWidthsObj],
  );

  return (
    <Wrap>
      <Container>
        <Title>컬리 배송 목록</Title>

        <SearchContainer />
        <Row>
          <ButtonsCol>
            <Button onClick={handleClickAllocatePickupRider}>수거배차</Button>
            <Button onClick={handleClickAllocatePickupByTypesInBulk}>
              픽업반품수거배차
            </Button>
            {/* <Button onClick={handleClickSetDeliveryStartedAll}>
              일괄배송출발
            </Button> */}
            <Button onClick={handleClickSetDeliveryStartedInBulk}>
              선택배송출발
            </Button>
            <ButtonItem onClick={handleClickAllocateDeliveryRider}>
              배송배차
            </ButtonItem>
            <ButtonItem onClick={handleClickSetWarehousedStatusBeforePickup}>
              미수거입고
            </ButtonItem>
            <ButtonItem onClick={handleClickSetWarehousedStatus}>
              입고처리
            </ButtonItem>
            <ButtonItem onClick={handleClickSetDeliveryCompletedStatus}>
              배송완료
            </ButtonItem>
            <Button onClick={handleClickSetPreviousStatus}>이전단계로</Button>
            <Button onClick={handleClickCancel}>취소처리</Button>
            <Button onClick={handleClickRestoreCancel}>취소해제</Button>
            <Button onClick={handleClickAccident}>사고처리</Button>
            <Button onClick={handleClickRestoreAccident}>사고해제</Button>
            <Button onClick={handleClickRefineAddresses}>주소정제</Button>
            <Button onClick={handleClickDownload}>목록다운</Button>
            <Button onClick={handleClickDownloadForBalanceAccount}>
              정산다운
            </Button>
            <Button onClick={handleClickDownloadForBalanceAccountV2}>
              정산다운V2
            </Button>
            {/* <Button onClick={handleClickDownloadForDeliveryCompleted}>
              일반배완다운
            </Button>
            <Button onClick={handleClickDownloadForPickupCompleted}>
              반품수완다운
            </Button> */}
            <Button onClick={handleClickWaybill}>운송장출력</Button>
            <Button onClick={handleClickCancelBulk}>대량취소</Button>
            <Button onClick={handleClickCancelForcedBulk}>
              대량취소(강제)
            </Button>
            <Button onClick={handleClickSyncKoomin}>배달업데이트</Button>
            <Button onClick={handleClickFindAgencyDelivery}>배달조회</Button>
          </ButtonsCol>
        </Row>
        <CheckBoxRow>
          <Col>
            <Form onChange={handleChangeCheckBoxForm}>
              <Form.Check
                id="filter-all"
                inline
                label={`전체(${state.count.all})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_ALL}
                ref={register}
              />
              <Form.Check
                id="filter-receipted"
                inline
                label={`접수(${state.count.receipted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIPTED}
                ref={register}
              />
              <Form.Check
                id="filter-pickup-scheduled"
                inline
                label={`수거지정(${state.count.pickupScheduled})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_PICKUP_SCHEDULED}
                ref={register}
              />
              <Form.Check
                id="filter-pickup-completed"
                inline
                label={`수거완료(${state.count.pickupCompleted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_PICKUP_COMPLETED}
                ref={register}
              />
              <Form.Check
                id="filter-warehoused"
                inline
                label={`입고(${state.count.warehoused})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_WAREHOUSED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-allocated"
                inline
                label={`배송배차(${state.count.deliveryAllocated})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_ALLOCATED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-postponed"
                inline
                label={`배송연기(${state.count.postponed})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_POSTPONED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-started"
                inline
                label={`배송출고(${state.count.deliveryStarted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_STARTED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-completed"
                inline
                label={`배송완료(${state.count.deliveryCompleted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_COMPLETED}
                ref={register}
              />
              <Form.Check
                id="filter-accident"
                inline
                label={`사고(${state.count.accident})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT}
                ref={register}
              />
              <Form.Check
                id="filter-canceled"
                inline
                label={`취소(${state.count.canceled})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED}
                ref={register}
              />
              <Form.Check
                id="filter-typePickup"
                inline
                label={`픽업(${state.count.typePickup})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP}
                ref={register}
              />
              <Form.Check
                id="filter-typeReturn"
                inline
                label={`반품(${state.count.typeReturn})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN}
                ref={register}
              />
              <Form.Check
                id="filter-customCustomerPostalCode"
                inline
                label={`우편번호지역(${state.count.customCustomerPostalCode})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE
                }
                ref={register}
              />
              <Form.Check
                id="filter-senderAddressError"
                inline
                label={`송하인주소오류(${state.count.senderAddressError})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR}
                ref={register}
              />
              <Form.Check
                id="filter-senderAddressErrorCanceled"
                inline
                label={`송하인주소오류취소(${state.count.senderAddressErrorCanceled})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED
                }
                ref={register}
              />
              <Form.Check
                id="filter-receiverAddressError"
                inline
                label={`수하인주소오류(${state.count.receiverAddressError})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR
                }
                ref={register}
              />
              <Form.Check
                id="filter-receiverAddressErrorCanceled"
                inline
                label={`수하인주소오류취소(${state.count.receiverAddressErrorCanceled})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED
                }
                ref={register}
              />
              <Form.Check
                id="filter-deliverySentBack"
                inline
                label={`반송완료(${state.count.deliverySentBack})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_SENT_BACK}
                ref={register}
              />
              <Form.Check
                id="filter-deliveryLost"
                inline
                label={`분실완료(${state.count.deliveryLost})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_LOST}
                ref={register}
              />
              <Form.Check
                id="filter-mobileError"
                inline
                label={`연락처오류(${state.count.mobileError})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR}
                ref={register}
              />
              <Form.Check
                id="filter-mobileErrorCanceled"
                inline
                label={`연락처오류취소(${state.count.mobileErrorCanceled})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED
                }
                ref={register}
              />
              <Form.Check
                id="filter-delayedDeliveries"
                inline
                label={`미배송기록(${state.count.delayed})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED}
                ref={register}
              />
              <Form.Check
                id="filter-addressNotSupported"
                inline
                label={`배송불가지역(${state.count.addressNotSupported})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED
                }
                ref={register}
              />
              <Form.Check
                id="filter-addressNotSupportedCanceled"
                inline
                label={`배송불가지역취소(${state.count.addressNotSupportedCanceled})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED
                }
                ref={register}
              />
              <Form.Check
                id="filter-agencyAllocationKookmin"
                inline
                label={`배달접수(${state.count.agencyAllocationKookmin})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN
                }
                ref={register}
              />
              <Form.Check
                id="filter-agencyAllocationFailed"
                inline
                label={`배달접수실패(${state.count.agencyAllocationFailed})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_FAILED
                }
                ref={register}
              />
            </Form>
          </Col>
        </CheckBoxRow>
        <Row>
          <CounterContainer fetchTime={fetchTime} fetch={fetch} />
        </Row>
        <TableRow>
          <TableCol>
            <DeliveriesTable
              deliveries
              responsive
              bordered
              hover
              columns={columns}
              currentPage={state.query.page}
              data={state.deliveries}
              goToPage={handleGoToPage}
              nextPage={handleNextPage}
              onClickHeader={handleClickHeader}
              onSelect={handleSelect}
              pageCount={state.pageCount}
              pageSize={state.query.pageSize}
              previousPage={handlePreviousPage}
              setPageSize={handleSetPageSize}
            />
          </TableCol>
        </TableRow>
      </Container>
    </Wrap>
  );
});
