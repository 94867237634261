import React from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import SpotForm from "@/forms/SpotForm";
import { useSpotsStore, useModalStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

export default ({ onCreate = _noop } = {}) => {
  const { ...actions } = useSpotsStore();
  const { closeModal } = useModalStore();

  const onSubmit = async (data) => {
    if (
      data.address &&
      data.code &&
      data.contact &&
      data.contactForLabel &&
      data.gatheringReference &&
      data.name &&
      data.postalCode
    ) {
      if (data.returnEnabled === "NONE") {
        data.returnActive = false;
      } else {
        data.returnActive = true;
      }

      if (!data.fee) {
        data.fee = 0;
      }

      actions
        .create(data)
        .then(() => {
          onCreate();
          closeModal();
        })
        .catch((e) => {
          window.alert(`수거지 생성에 실패했습니다: ${e.message}`);
        });
    } else {
      window.alert("필수 항목을 입력해주세요.");
    }
  };

  return (
    <Modal size="lg">
      <Modal.Header>
        <Title>수거지 등록</Title>
      </Modal.Header>
      <Modal.Body>
        <SpotForm onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
};
