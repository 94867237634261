import React, { memo, useCallback, useEffect, useState } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import daumAddress from "@/lib/daum-address";
import Modal from "@/components/Modal";
import { WaybillModal } from "@/Modals";
import { DeliveryStatusLog } from "@/Models";

import {
  useDelayedDeliveriesStore,
  useDeliveriesStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";
import {
  Delivery,
  DELIVERY_TYPE_PICKUP,
  DELIVERY_TYPE_NORMAL,
  DELIVERY_TYPE_RETURN,
} from "@/Models/Delivery";

const Td = styled.td`
  width: calc(100% - 200px);
  padding: 0.5rem !important;
  word-break: break-all;
`;

const Th = styled.th`
  width: 200px;
  padding: 0.5rem !important;
`;

const Title = styled.h3`
  margin: 0;
`;
const SubTitle = styled.h6`
  margin-top: 10px;
  font-weight: bold;
`;

const FlexSpaceWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogsWrap = styled.div``;
const LogItem = styled.div``;

const renderBookId = (bookId, type) => {
  switch (type) {
    case DELIVERY_TYPE_PICKUP:
      return `(픽) ${bookId}`;
    default:
      return bookId;
  }
};

const AddressUpdateField = memo(
  ({ initialValue, onSearch, name, prevPage }) => {
    const [address, setAddress] = useState("");
    useEffect(() => {
      if (initialValue) {
        setAddress(initialValue);
      }
    }, [initialValue]);

    const handleClickSearchButton = useCallback(async () => {
      const result = await daumAddress();

      onSearch(name, result);
      setAddress(result.jibunAddress);
    }, [daumAddress]);

    if (prevPage === "CS") {
      return address;
    }

    return (
      <Form>
        <Form.Row>
          <Col sm={9}>
            <Form.Control value={address} disabled />
          </Col>
          <Col sm="auto">
            <Button onClick={handleClickSearchButton} type="button">
              검색
            </Button>
          </Col>
        </Form.Row>
      </Form>
    );
  },
);

// 배송정제
const AddressUpdateByOneKakao = memo(
  ({ type, bookId, onAfterUpdate = _noop, fetchById, prevPage }) => {
    const { ...actions } = useDeliveriesStore();

    const [address, setAddress] = useState("");
    const [addressDetail, setAddressDetail] = useState("");

    const handleClickSearchButton = useCallback(async () => {
      const result = await daumAddress();

      setAddress(result.roadAddress);
    }, [daumAddress]);

    const handleChangeAddressDetail = useCallback((e) => {
      setAddressDetail(e.target.value);
    }, []);

    const handleSubmit = useCallback(async () => {
      if (address) {
        try {
          await actions.updateAddress(bookId, {
            type,
            address,
            addressDetail,
          });

          window.alert("주소 정제에 성공했습니다.");

          onAfterUpdate();
          fetchById();
        } catch (e) {
          window.alert(`주소 정제 실패하였습니다.\n에러메시지: ${e.message}`);
        }
      }
    }, [address, addressDetail, type, actions.updateAddress]);

    const FormRow = styled(Form.Row)`
      margin-bottom: 10px;
    `;

    if (prevPage === "CS") {
      return "";
    }

    return (
      <Form>
        <FormRow>
          <Col sm={9}>
            <Form.Control name="address" value={address} disabled />
          </Col>
          <Col sm="auto">
            <Button onClick={handleClickSearchButton} type="button">
              검색
            </Button>
          </Col>
        </FormRow>
        <Form.Row>
          <Col sm={9}>
            <Form.Control
              name="addressDetail"
              value={addressDetail}
              onChange={handleChangeAddressDetail}
            />
          </Col>
          <Col sm="auto">
            <Button onClick={handleSubmit} type="button">
              주소 정제
            </Button>
          </Col>
        </Form.Row>
      </Form>
    );
  },
);

// 배송취소
const CancelField = memo(({ onSubmit }) => {
  return (
    <Button type="button" onClick={onSubmit}>
      배송 불가
    </Button>
  );
});

const TextUpdateField = memo(
  ({
    buttonText = "수정",
    placeholder = "",
    initialValue,
    onSubmit,
    name,
    number = false,
    prevPage,
  }) => {
    const [text, setText] = useState("");

    useEffect(() => {
      if (initialValue) {
        setText(initialValue);
      }
    }, [initialValue]);

    const handleChange = useCallback(
      (e) => {
        setText(number ? (e.target.value || "").trim() : e.target.value);
      },
      [setText],
    );

    const handleSubmit = useCallback(
      (e) => {
        e.preventDefault();
        onSubmit(name, text);
      },
      [text],
    );

    if (prevPage === "CS") {
      return text;
    }

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Col sm={9}>
            <Form.Control
              placeholder={placeholder}
              onChange={handleChange}
              value={text}
            />
          </Col>
          <Col sm="auto">
            <Button type="submit">{buttonText}</Button>
          </Col>
        </Form.Row>
      </Form>
    );
  },
);

const Etc4UpdateField = memo(
  ({
    buttonText = "수정",
    placeholder = "",
    initialEtc4,
    initialValue,
    onSubmit,
    name,
    number = false,
    prevPage,
  }) => {
    const [text, setText] = useState("");
    const [etc4, setEtc4] = useState({});

    useEffect(() => {
      if (initialValue) {
        setText(initialValue);
      }

      if (initialEtc4) {
        setEtc4(JSON.parse(initialEtc4));
      }
    }, [initialEtc4, initialValue]);

    const handleChange = useCallback(
      (e) => {
        setText(number ? (e.target.value || "").trim() : e.target.value);
        setEtc4({
          ...etc4,
          [name]: number ? (e.target.value || "").trim() : e.target.value,
        });
      },
      [etc4, setText, setEtc4],
    );

    const handleSubmit = useCallback(
      (e) => {
        e.preventDefault();

        onSubmit("etc4", JSON.stringify(etc4));
      },
      [etc4],
    );

    if (prevPage === "CS") {
      return text;
    }

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Col sm={9}>
            <Form.Control
              placeholder={placeholder}
              onChange={handleChange}
              value={text}
            />
          </Col>
          <Col sm="auto">
            <Button type="submit">{buttonText}</Button>
          </Col>
        </Form.Row>
      </Form>
    );
  },
);

export default ({ bookId, onAfterUpdate = _noop, prevPage, ...props }) => {
  const { openModal, closeModal } = useModalStore();
  const { ...delayedDeliveriesActions } = useDelayedDeliveriesStore();
  const { ...actions } = useDeliveriesStore();
  const { finishLoading, startLoading } = useLoadingStore();

  const handleClickPrint = useCallback((deliveries) => {
    openModal(<WaybillModal key="waybill-modal" deliveries={deliveries} />);
  }, []);

  const handleSearchAddress = useCallback(
    async (name, { jibunAddress, roadAddress }) => {
      try {
        const params = {};
        switch (name) {
          case "senderAddress":
            params.senderAddress = jibunAddress;
            params.senderAddressRoad = roadAddress;
            break;
          case "receiverAddress":
            params.receiverAddress = jibunAddress;
            params.receiverAddressRoad = roadAddress;
            break;
          default:
            break;
        }

        startLoading();
        await actions.update(bookId, params);

        window.alert("배송 정보 수정이 완료되었습니다.");
        onAfterUpdate();
        fetchById();
        finishLoading();
      } catch (e) {
        finishLoading();
        window.alert(
          `배송정보 수정에 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    },
    [bookId, onAfterUpdate],
  );

  const handleUpdate = useCallback(
    async (name, value) => {
      try {
        startLoading();
        await actions.update(bookId, {
          [name]: value,
        });

        window.alert("배송 정보 수정이 완료되었습니다.");
        onAfterUpdate();
        fetchById();
        finishLoading();
      } catch (e) {
        finishLoading();
        window.alert(
          `배송정보 수정에 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    },
    [bookId, onAfterUpdate],
  );

  const cancelByHellovision = useCallback(async () => {
    if (window.confirm("배송불가 처리하시겠습니까?")) {
      try {
        await actions.cancelByHellovision(bookId);

        window.alert("배송 불가 처리되었습니다.");
        onAfterUpdate();
        fetchById();
      } catch (e) {
        window.alert(
          `배송불가 처리에 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    }
  }, [bookId, onAfterUpdate]);

  useEffect(() => {
    fetchById();
    fetchDeliveryStatusLogs();
  }, []);

  const [rowsDefault, setRowsDefault] = useState([]);
  const [rowsSender, setRowsSender] = useState([]);
  const [rowsReceiver, setRowsReceiver] = useState([]);
  const [rowsProduct, setRowsProduct] = useState([]);
  const [rowsTracking, setRowsTracking] = useState([]);
  const [rowsLog, setRowsLog] = useState([]);

  const fetchById = useCallback(async () => {
    try {
      const _delivery = await actions.fetchByIdOnTheFly(bookId);
      const delayedDeliveries = await delayedDeliveriesActions.fetchLatestOneByBookId(
        bookId,
      );

      const delivery = new Delivery(_delivery);

      let _rowsDefault = [
        {
          header: "예약번호 (배송상태)",
          data: (
            <FlexSpaceWrap>
              <div>
                {renderBookId(delivery.bookId, delivery.type)} (
                {delivery.renderStatus()}
                {delivery.cjOrderIdFromCorpFromOliveyoung
                  ? ", CJ대한통운 배송"
                  : ""}
                )
                {delivery.deliveryRiderName
                  ? ` [${delivery.deliveryRiderName}${
                      delivery.orderingNumberByDeliveryRiderId
                        ? ` ${delivery.orderingNumberByDeliveryRiderId}`
                        : ""
                    }]`
                  : ""}
              </div>
              <Button
                onClick={() => handleClickPrint([_delivery])}
                variant="secondary"
              >
                운송장 다운로드
              </Button>
            </FlexSpaceWrap>
          ),
        },
        {
          header: "사측 주문번호",
          data: delivery.orderIdFromCorp,
        },
        {
          header: "접수일시",
          data: `${formatDate(
            getDateByTimeZone(delivery.receiptDate),
            "MM/dd HH:mm",
          )}${
            delivery.requestedDeliveryTime
              ? ` (배송요청일시: ${delivery.renderRequestedDeliveryTime()})`
              : ""
          }`,
        },
        {
          header: "접수점",
          data: delivery.spot ? delivery.spot.name : "",
        },
        {
          header: "접수점 연락처",
          data: delivery.spot ? delivery.spot.contact : "",
        },
        {
          header: "접수점 주소",
          data: delivery.spot ? delivery.spot.address : "",
        },
      ];

      let _rowsSender = [
        {
          header: "송하인명",
          data: (
            <TextUpdateField
              initialValue={delivery.senderName}
              onSubmit={handleUpdate}
              name="senderName"
              prevPage={prevPage}
            />
          ),
        },
        {
          header: "송하인 연락처",
          data: (
            <TextUpdateField
              initialValue={delivery.senderMobile}
              onSubmit={handleUpdate}
              name="senderMobile"
              number
              prevPage={prevPage}
            />
          ),
        },
        {
          header: "송하인 연락처2",
          data: (
            <TextUpdateField
              initialValue={delivery.senderMobile2}
              onSubmit={handleUpdate}
              name="senderMobile2"
              number
              prevPage={prevPage}
            />
          ),
        },
        {
          header: "주소 수정",
          data: (
            <AddressUpdateByOneKakao
              type="sender"
              bookId={delivery.bookId}
              onAfterUpdate={onAfterUpdate}
              fetchById={fetchById}
              prevPage={prevPage}
            />
          ),
        },
        {
          header: "지역그룹/동",
          data: `${delivery.senderAllocationGroupNameOnWeekdays}(주중) / ${delivery.senderAllocationGroupNameOnWeekends}(주말)`,
        },
        {
          header: "송하인 우편번호",
          data: delivery.senderPostalCode || delivery.senderAddressPostalCode,
        },
        {
          header: "송하인 주소(지번)",
          data: delivery.senderAddress,
        },
        {
          header: "송하인 주소(도로명)",
          data: delivery.senderAddressRoad,
        },
      ];

      if (
        delivery.type === DELIVERY_TYPE_PICKUP ||
        delivery.type === DELIVERY_TYPE_RETURN
      ) {
        _rowsSender.push({
          header: "송하인 아파트동",
          data: delivery.senderBuildingDongNumber || "없음",
        });
      }

      _rowsSender.push({
        header: "송하인 상세주소",
        data: delivery.senderAddressDetail,
      });

      _rowsSender.push({
        header: "위치(위도, 경도)",
        data: `${delivery.senderLnglat.latitude}, ${delivery.senderLnglat.longitude}`,
      });

      if (
        delivery.type === DELIVERY_TYPE_PICKUP ||
        delivery.type === DELIVERY_TYPE_RETURN
      ) {
        _rowsSender.push({
          header: "주소정제 API",
          data: delivery.renderAddressApiType,
        });
        _rowsSender.push({
          header: "고객 입력 주소",
          data: (
            <TextUpdateField
              initialValue={
                delivery.unrefinedAddress ||
                `${delivery.customerAddress ||
                  ""} ${delivery.customerAddressDetail || ""}`
              }
              onSubmit={handleUpdate}
              name="unrefinedAddress"
              prevPage={prevPage}
            />
          ),
        });
      }

      let _rowsReceiver = [
        {
          header: "수하인명",
          data: (
            <TextUpdateField
              initialValue={delivery.receiverName}
              onSubmit={handleUpdate}
              name="receiverName"
              prevPage={prevPage}
            />
          ),
        },
        {
          header: "수하인 연락처",
          data: (
            <TextUpdateField
              initialValue={delivery.receiverMobile}
              onSubmit={handleUpdate}
              name="receiverMobile"
              number
              prevPage={prevPage}
            />
          ),
        },
        {
          header: "수하인 연락처2",
          data: (
            <TextUpdateField
              initialValue={delivery.receiverMobile2}
              onSubmit={handleUpdate}
              name="receiverMobile2"
              number
              prevPage={prevPage}
            />
          ),
        },
        {
          header: "주소 수정",
          data: (
            <AddressUpdateByOneKakao
              type="receiver"
              bookId={delivery.bookId}
              onAfterUpdate={onAfterUpdate}
              fetchById={fetchById}
              prevPage={prevPage}
            />
          ),
        },
        {
          header: "지역그룹/동",
          data: `${delivery.receiverAllocationGroupNameOnWeekdays}(주중) / ${delivery.receiverAllocationGroupNameOnWeekends}(주말)`,
        },
        {
          header: "수하인 우편번호",
          data:
            delivery.receiverPostalCode || delivery.receiverAddressPostalCode,
        },
        {
          header: "수하인 주소(지번)",
          data: delivery.receiverAddress,
        },
        {
          header: "수하인 주소(도로명)",
          data: delivery.receiverAddressRoad,
        },
        {
          header: "수하인 아파트동",
          data: delivery.receiverBuildingDongNumber || "없음",
        },
        {
          header: "수하인 상세주소",
          data: delivery.receiverAddressDetail,
        },
        {
          header: "위치(위도, 경도)",
          data: `${delivery.receiverLnglat.latitude}, ${
            delivery.receiverLnglat.longitude
          } ${
            delivery.soojioneLimitedCustomizedLngLat
              ? `[주의] ${delivery.soojioneLimitedCustomizedLngLat.latitude}, ${delivery.soojioneLimitedCustomizedLngLat.longitude}`
              : ""
          }`,
        },
        {
          header: "주소정제 API",
          data: delivery.renderAddressApiType,
        },
      ];

      if (delivery.type === DELIVERY_TYPE_NORMAL) {
        _rowsReceiver.push({
          header: "고객입력 우편번호",
          data: delivery.customerAddressPostalCode,
        });

        _rowsReceiver.push({
          header: "고객 입력 주소",
          data: (
            <TextUpdateField
              initialValue={
                delivery.unrefinedAddress ||
                `${delivery.customerAddress ||
                  ""} ${delivery.customerAddressDetail || ""}`
              }
              onSubmit={handleUpdate}
              name="unrefinedAddress"
              prevPage={prevPage}
            />
          ),
        });

        if (delivery.customerAddressEng) {
          _rowsReceiver.push({
            header: "영문입력 주소",
            data: delivery.customerAddressEng,
          });
        }

        if (delivery.customerAddressEngGpt) {
          _rowsReceiver.push({
            header: "쳇지피티 번역결과",
            data: delivery.customerAddressEngGpt,
          });
        }
      }

      if (delivery.receiverAddressUpdatedBy) {
        _rowsReceiver.push({
          header: "주소 수정 로그",
          data: `${delivery.receiverAddressUpdatedByName}(${
            delivery.receiverAddressUpdatedBy
          })/${delivery.renderReceiverAddressUpdatedAt()}`,
        });
      }

      let _rowsProduct = [
        {
          header: "물품 분류",
          data: delivery.productCategory,
        },
        {
          header: "물품명",
          data: delivery.productName,
        },
        {
          header: "물품 가격",
          data:
            new Intl.NumberFormat({
              style: "currency",
              currency: "KRW",
            }).format(delivery.productPrice) + "원",
        },
        {
          header: "고객요청사항",
          data: (
            <TextUpdateField
              initialValue={delivery.memoFromCustomer}
              onSubmit={handleUpdate}
              name="memoFromCustomer"
              prevPage={prevPage}
            />
          ),
        },
        {
          header: "수령희망장소",
          data: delivery.deliveryPlace
            ? `(${formatDate(
                getDateByTimeZone(delivery.deliveryPlacedTime || new Date()),
                "HH:mm",
              )})${delivery.deliveryPlace}`
            : "",
        },
        {
          header: "현관비밀번호",
          data: (
            <>
              <Etc4UpdateField
                placeholder="업체입력 현관비밀번호"
                initialEtc4={delivery.etc4}
                initialValue={delivery.frontdoorPasswordByCorp}
                onSubmit={handleUpdate}
                name="frontdoorPasswordByCorp"
                prevPage={prevPage}
              />
              <Etc4UpdateField
                placeholder="고객입력 현관비밀번호"
                initialEtc4={delivery.etc4}
                initialValue={delivery.frontdoorPasswordByClient}
                onSubmit={handleUpdate}
                name="frontdoorPasswordByClient"
                prevPage={prevPage}
              />
            </>
          ),
        },
      ];
      let _rowsTracking = [
        {
          header: "취소여부",
          data: delivery.isCanceled ? "O" : "X",
        },
        {
          header: "취소날짜",
          data: delivery.renderCanceledAt(),
        },
        {
          header: "취소사유",
          data: delivery.canceledReason,
        },
        {
          header: "사고여부",
          data: delivery.isAccident ? "O" : "X",
        },
        {
          header: "사고날짜",
          data: delivery.renderAccidentAt(),
        },
        {
          header: "사고사유",
          data: delivery.accidentReason,
        },
        {
          header: "기타사항1",
          data: (
            <TextUpdateField
              initialValue={delivery.etc1}
              onSubmit={handleUpdate}
              name="etc1"
              prevPage={prevPage}
            />
          ),
        },
        {
          header: "기타사항2",
          data: (
            <TextUpdateField
              initialValue={delivery.etc2}
              onSubmit={handleUpdate}
              name="etc2"
              prevPage={prevPage}
            />
          ),
        },
        {
          header: "기타사항3",
          data: (
            <TextUpdateField
              initialValue={delivery.etc3}
              onSubmit={handleUpdate}
              name="etc3"
              prevPage={prevPage}
            />
          ),
        },
        {
          header: "수취인 서명 이미지",
          data: delivery.signImageLocation && (
            <a
              href={delivery.signImageLocation}
              target="_blank"
              rel="noreferrer"
            >
              <img alt="" src={delivery.signImageLocation} width="100%" />
            </a>
          ),
        },
        {
          header: "미수취 촬영 이미지",
          data: delivery.notReceivedImageLocation && (
            <a
              href={delivery.notReceivedImageLocation}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt=""
                src={delivery.notReceivedImageLocation}
                width="100%"
              />
            </a>
          ),
        },
        {
          header: "배완사유",
          data: delivery.renderManualCompletedDeliveryReason,
        },
        {
          header: "미배송",
          data: delayedDeliveries.length
            ? `사유: ${delayedDeliveries[0].reason}\n일시: ${formatDate(
                new Date(delayedDeliveries[0].createdAt),
                "yyyy-MM-dd HH:mm",
              )}`
            : "",
        },
        {
          header: "반송완료",
          data: delivery.sentBackLogs.length
            ? delivery.sentBackLogs
                .map(
                  (dd) =>
                    `${formatDate(
                      getDateByTimeZone(dd.createdAt),
                      "MM/dd HH:mm",
                    )} : ${dd.reason}`,
                )
                .join("\n")
            : "",
        },
        {
          header: "분실완료",
          data: `${delivery.renderDeliveryLostDate() || ""} ${
            delivery.lostReason
          }`,
        },
      ];

      if (delivery.pickupCompletedImage) {
        _rowsTracking.push({
          header: "반품수거 촬영 이미지",
          data: delivery.pickupCompletedImage && (
            <a
              href={delivery.pickupCompletedImage}
              target="_blank"
              rel="noreferrer"
            >
              <img alt="" src={delivery.pickupCompletedImage} width="100%" />
            </a>
          ),
        });
      }

      if (delivery.spot && delivery.spot.code === "10241") {
        _rowsTracking.push({
          header: "배송 불가 처리",
          data: <CancelField onSubmit={cancelByHellovision} />,
        });
      }

      if (delivery.deliveryDoneLatitude && delivery.deliveryDoneLongitude) {
        _rowsTracking.push({
          header: "배송완료 위치(위경도)",
          data: `${delivery.deliveryDoneLatitude}, ${delivery.deliveryDoneLongitude}`,
        });
      }

      if (delivery.completedLocation) {
        _rowsTracking.push({
          header: "배송완료 위치(장소) 및 안내 문자발송 여부",
          data: `${delivery.completedLocation} / ${
            delivery.sendedCompletedSms ? "발송완료" : "미발송"
          }`,
        });
      }

      if (
        delivery.deliverySentBackLatitude &&
        delivery.deliverySentBackLongitude
      ) {
        _rowsTracking.push({
          header: "반송완료 위치(위경도)",
          data: `${delivery.deliverySentBackLatitude}, ${delivery.deliverySentBackLongitude}`,
        });
      }

      if (delivery.deliveryLostLatitude && delivery.deliveryLostLongitude) {
        _rowsTracking.push({
          header: "분실완료 위치(위경도)",
          data: `${delivery.deliveryLostLatitude}, ${delivery.deliveryLostLongitude}`,
        });
      }

      if (delivery.updateDeliveryCompletedStateByAdmin) {
        _rowsTracking.push({
          header: "외부업체 재시도 배송완료 처리", // O 2022-08-02 10:10 전일현
          data: `${delivery.updateDeliveryCompletedStateByAdmin ? "O" : "X"} ${
            delivery.updateDeliveryCompletedStateAt
              ? formatDate(
                  getDateByTimeZone(delivery.updateDeliveryCompletedStateAt),
                  "yyyy-MM-dd HH:mm",
                )
              : ""
          } ${delivery.updateDeliveryCompletedStateBy}`,
        });
      }

      if (delivery.agencyOrderId) {
        _rowsTracking.push({
          header: "QDN주문_일련번호",
          data: delivery.agencyOrderId,
        });

        _rowsTracking.push({
          header: "배송원성명(배송원연락처)",
          data: `${delivery.agencyDeliveryRiderName ||
            ""}(${delivery.agencyDeliveryRiderMobile || ""})`,
        });
      }

      if (delivery.agencyNotReceivedImageLocation) {
        _rowsTracking.push({
          header: "기타 이미지",
          data: (
            <a
              href={delivery.agencyNotReceivedImageLocation}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt=""
                src={delivery.agencyNotReceivedImageLocation}
                width="100%"
              />
            </a>
          ),
        });
      }

      setRowsDefault(_rowsDefault);
      setRowsSender(_rowsSender);
      setRowsReceiver(_rowsReceiver);
      setRowsProduct(_rowsProduct);
      setRowsTracking(_rowsTracking);
    } catch (e) {
      window.alert(e.message);
      closeModal();
    }
  });

  const fetchDeliveryStatusLogs = useCallback(async () => {
    try {
      const { rows } = await actions.fetchDeliveryStatusLogs(bookId);

      const _deliveryStatusLogs = rows?.sort((a, b) => {
        return a.createdAt < b.createdAt ? -1 : 1;
      });

      setRowsLog(_deliveryStatusLogs?.map((d) => new DeliveryStatusLog(d)));
    } catch (e) {
      window.alert(`배송 로그를 불러오는데 실패했습니다. ${e.message}`);
    }
  }, []);

  const TableRows = ({ row }) => {
    return (
      <tr>
        <Th>{row.header}</Th>
        <Td>
          {typeof row.data === "string"
            ? row.data.split("\n").map((line, idx) => {
                return (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                );
              })
            : row.data}
        </Td>
      </tr>
    );
  };

  const LogRows = ({ row }) => {
    return (
      <LogItem>
        {row.isAgency ? "(배)" : ""} {row.renderCreatedAt()}{" "}
        {row.renderLogStatus()}
        {row.isAgency
          ? ""
          : `(${row.renderStatusBefore()}→${row.renderStatusAfter()})`}{" "}
        {row.logWriter}
        {row.agencyDeliveryRiderName
          ? `| 배달지정배송원명: ${row.agencyDeliveryRiderName}`
          : ""}
        {row.agencyFailedReason ? `| 실패사유: ${row.agencyFailedReason}` : ""}
      </LogItem>
    );
  };

  return (
    <Modal size="lg" {...props}>
      <Modal.Header>
        <Title>배송 정보</Title>
      </Modal.Header>
      <Modal.Body>
        <SubTitle>기본정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsDefault.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
        <SubTitle>송하인 정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsSender.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
        <SubTitle>수하인 정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsReceiver.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
        <SubTitle>물품정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsProduct.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
        <SubTitle>배송정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsTracking.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
        <SubTitle>배송로그</SubTitle>
        <LogsWrap>
          {rowsLog.map((row, i) => (
            <LogRows row={row} key={i} />
          ))}
        </LogsWrap>
      </Modal.Body>
    </Modal>
  );
};
