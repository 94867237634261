import React, { useCallback, useEffect } from "react";
import BootstrapAccordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Button } from "@/components/Buttons";
import { useAuthStore, useCorpUploadFailedStore } from "@/store/hooks";

const Accordion = styled(BootstrapAccordion)`
  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;

const Nav = styled.nav`
  position: fixed;
  min-width: 15%;
  overflow: auto;
  height: ${({ theme }) => `calc(100vh - ${theme.constants.NAV_HEIGHT})`};
  padding-bottom: 40px;
`;

const Li = styled.li`
  font-size: 16px;
`;

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const LogoutButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

export default () => {
  const { state, ...actions } = useAuthStore();
  const {
    state: corpUploadFailedState,
    ...corpUploadFailedActions
  } = useCorpUploadFailedStore();

  useEffect(() => {
    fetchCorpUploadFailedCount();
  }, []);

  const fetchCorpUploadFailedCount = useCallback(async () => {
    try {
      await corpUploadFailedActions.fetchCount();
    } catch (e) {
      console.log(e);
      // window.alert(
      //   `외부업체 업로드 실패 수량을 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`
      // );
    }
  }, [corpUploadFailedActions.fetchCount]);

  const signout = useCallback(() => {
    actions.signOut();
  }, [actions.signOut]);

  return state.user.authority !== 3 ? (
    <Nav>
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <BootstrapAccordion.Toggle
              as={styled.h5``}
              variant="link"
              eventKey="0"
            >
              배송 관리
            </BootstrapAccordion.Toggle>
          </Card.Header>
          <BootstrapAccordion.Collapse eventKey="0">
            <Card.Body>
              <Ul>
                <Li>
                  <Link to="/deliveries">배송 목록</Link>
                </Li>
                <Li>
                  <Link to="/deliveries-kurly">컬리 배송 목록</Link>
                </Li>
                <Li>
                  <Link to="/deliveries-cjthemarket">CJ더마켓 배송 목록</Link>
                </Li>
                <Li>
                  <Link to="/deliveries-returns">반품 목록</Link>
                </Li>
                <Li>
                  <Link to="/deliveries-pickups">픽업 목록</Link>
                </Li>
                <Li>
                  <Link to="/deliveries-misclassification">
                    반품/반송/오분류 관리
                  </Link>
                </Li>
                <Li>
                  <Link to="/deliveries-summary">배송 기록</Link>
                </Li>
                <Li>
                  <Link to="/deliveries-monthly">월별 조회</Link>
                </Li>
                <Li>
                  <Link to="/reallocated-deliveries">재배차 신청 목록</Link>
                </Li>
                <Li>
                  <Link to="/reallocation-logs">재배차 이력</Link>
                </Li>
                <Li>
                  <Link to="/hellovision">헬로비전 수동 관리</Link>
                </Li>
                <Li>
                  <Link to="/hanjin">한진 연동 관리</Link>
                </Li>
                <Li>
                  <Link to="/address">주소 정제 테스트</Link>
                </Li>
                <Li>
                  <Link to="/address-soojione">수지원 자동 정제 결과</Link>
                </Li>
                <Li>
                  <Link to="/translate">번역 테스트</Link>
                </Li>
                <Li>
                  <Link to="/reviews">리뷰 관리</Link>
                </Li>
                <Li>
                  <Link to="/cses">CS 관리</Link>
                </Li>
                <Li>
                  <Link to="/corp-callbacks">기업회원별 콜백 현황</Link>
                </Li>
                <Li>
                  <Link to="/corp-upload-failed">
                    외부업체 업로드 실패 관리(
                    {Object.values(
                      corpUploadFailedState.uploadFailedsCount,
                    ).reduce((a, b) => a + b)}
                    )
                  </Link>
                </Li>
                <Li>
                  <Link to="/corp-scheduler">외부업체 스케쥴러 수동처리</Link>
                </Li>
                <Li>
                  <Link to="/returns">회송 반품 등록</Link>
                </Li>
                <Li>
                  <Link to="/failed-scan"> 바코드 스캔 실패 조회</Link>
                </Li>
              </Ul>
            </Card.Body>
          </BootstrapAccordion.Collapse>
        </Card>
      </Accordion>

      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <BootstrapAccordion.Toggle
              as={styled.h5``}
              variant="link"
              eventKey="0"
            >
              배차 관련
            </BootstrapAccordion.Toggle>
          </Card.Header>
          <BootstrapAccordion.Collapse eventKey="0">
            <Card.Body>
              <Ul>
                <Li>
                  <Link to="/allocation-groups">지역그룹 관리</Link>
                </Li>
                <Li>
                  <Link to="/allocation-matches">배차 시간표 관리</Link>
                </Li>
                <Li>
                  <Link to="/allocations">배차 현황</Link>
                </Li>
                <Li>
                  <Link to="/ag-postal-codes">우편번호 관리</Link>
                </Li>
                <Li>
                  <Link to="/ag-postal-codes-by-corp">
                    외부업체 대응 우편번호 관리
                  </Link>
                </Li>
                <Li>
                  <Link to="/dongs-not-supported">
                    배송불가지역 동정보 관리
                  </Link>
                </Li>
                <Li>
                  <Link to="/dongs">동 관리</Link>
                </Li>
                <Li>
                  <Link to="/custom-dongs">커스텀 동 관리</Link>
                </Li>
                <Li>
                  <Link to="/base-addresses">주소 정보 관리</Link>
                </Li>
                <Li>
                  <Link to="/base-addresses-corrections">주소용어집 관리</Link>
                </Li>
                <Li>
                  <Link to="/holidays">휴일 관리</Link>
                </Li>
              </Ul>
            </Card.Body>
          </BootstrapAccordion.Collapse>
        </Card>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <BootstrapAccordion.Toggle
              as={styled.h5``}
              variant="link"
              eventKey="0"
            >
              라이더 관련
            </BootstrapAccordion.Toggle>
          </Card.Header>
          <BootstrapAccordion.Collapse eventKey="0">
            <Card.Body>
              <Ul>
                <Li>
                  <Link to="/riders">라이더 관리</Link>
                </Li>
                <Li>
                  <Link to="/deleted-riders">삭제된 라이더 관리</Link>
                </Li>
                <Li>
                  <Link to="/riders-location">라이더 위치</Link>
                </Li>
                <Li>
                  <Link to="/rider-groups">라이더 그룹 관리</Link>
                </Li>
                <Li>
                  <Link to="/rider-notices">라이더 공지사항</Link>
                </Li>
              </Ul>
            </Card.Body>
          </BootstrapAccordion.Collapse>
        </Card>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <BootstrapAccordion.Toggle
              as={styled.h5``}
              variant="link"
              eventKey="0"
            >
              기업회원 관련
            </BootstrapAccordion.Toggle>
          </Card.Header>
          <BootstrapAccordion.Collapse eventKey="0">
            <Card.Body>
              <Ul>
                <Li>
                  <Link to="/corp-users">기업회원 관리</Link>
                </Li>
                <Li>
                  <Link to="/deleted-corp-users">삭제된 기업회원 관리</Link>
                </Li>
                <Li>
                  <Link to="/spots">수거지 관리</Link>
                </Li>
                <Li>
                  <Link to="/spots-requested">임시 수거지 관리</Link>
                </Li>
              </Ul>
            </Card.Body>
          </BootstrapAccordion.Collapse>
        </Card>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <BootstrapAccordion.Toggle
              as={styled.h5``}
              variant="link"
              eventKey="0"
            >
              기타
            </BootstrapAccordion.Toggle>
          </Card.Header>
          <BootstrapAccordion.Collapse eventKey="0">
            <Card.Body>
              <Ul>
                <Li>
                  <Link to="/statistics">통계</Link>
                </Li>
                <Li>
                  <Link to="/downloads">통합 엑셀 다운로드</Link>
                </Li>
                <Li>
                  <Link to="/alimtalk-notice">알림톡 공지메시지</Link>
                </Li>
                <Li>
                  <Link to="/users">관리자 관리</Link>
                </Li>
                {/* <Li>
                    <Link to="/session-logs">관제 접속 로그</Link>
                  </Li>
                  <Li>
                    <Link to="/logs">관제 액션 로그</Link>
                  </Li> */}
              </Ul>
            </Card.Body>
          </BootstrapAccordion.Collapse>
        </Card>
      </Accordion>

      <LogoutButton type="button" variant="secondary" onClick={signout}>
        로그아웃
      </LogoutButton>
    </Nav>
  ) : (
    <Nav>
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <BootstrapAccordion.Toggle
              as={styled.h5``}
              variant="link"
              eventKey="0"
            >
              배차 관련
            </BootstrapAccordion.Toggle>
          </Card.Header>
          <BootstrapAccordion.Collapse eventKey="0">
            <Card.Body>
              <Ul>
                <Li>
                  <Link to="/allocations">배차 현황</Link>
                </Li>
              </Ul>
            </Card.Body>
          </BootstrapAccordion.Collapse>
        </Card>
      </Accordion>
      <LogoutButton type="button" variant="secondary" onClick={signout}>
        로그아웃
      </LogoutButton>
    </Nav>
  );
};
