import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import _findIndex from "lodash/findIndex";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { lastDayOfMonth } from "date-fns";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { FindRiderModal } from "@/Modals";

import { DeliveriesStore } from "@/store";
import {
  useDeliveriesStore,
  useDeliveriesMonthlyStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const ButtonsCol = styled(Col)`
  margin-left: -2.5px;
  margin-right: -2.5px;

  button {
    margin-left: 2.5px;
    margin-right: 2.5px;
    margin-bottom: 5px;
  }
`;

const CheckBoxRow = styled(Row)`
  margin-top: 5px;
`;

const ColFlex = styled(Col)`
  flex: ${({ flex }) => flex || 1};
  padding-left: 5px;
  padding-right: 5px;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
`;

const CounterCol = styled(Col)`
  display: inline-block;
  margin: 10px 0;
  text-align: right;
`;

const DatePickerCol = styled(Col)`
  padding: 0;
  display: flex;
  flex-direction: row;

  input {
    width: calc(50% - 15px);
  }
`;

const DeliveriesTable = styled(Table)`
  font-size: 14px;
  min-width: 1800px;
`;

const FormLabel = styled(Form.Label)`
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: right;
  font-size: 14px;
`;

const FormRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;

const PaddingCol = styled(Col)`
  padding: 0;
  padding-left: ${(props) => (props.left ? props.left : 0)}px;
`;

const SubmitCol = styled(ColFlex)`
  text-align: right;
`;

const TableRow = styled(Row)`
  overflow: auto;
  flex: 1;
`;
const TableCol = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Wave = styled.span`
  margin: 0 5px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ theme }) => `calc(100vh - ${theme.constants.NAV_HEIGHT})`};
`;

const CounterContainer = memo(({ fetch, fetchTime }) => {
  const { loading } = useLoadingStore().state;
  const { openedModal } = useModalStore().state;

  const sec = 1000;
  const [count, setCount] = useState(600 * sec);
  const [pause, setPause] = useState(false);

  const handlePause = useCallback(() => {
    setPause(!pause);
  }, [pause]);

  const counter = useCallback(() => {
    if (count === 0) {
      setCount(600000);

      if (!loading) {
        fetch();
      }
    } else {
      setCount(count - sec);
    }
  }, [count, setCount, loading]);

  useEffect(() => {
    if (!pause && !openedModal) {
      let id = setInterval(counter, sec);
      return () => clearInterval(id);
    }
  }, [pause, openedModal, counter]);

  return (
    <CounterCol>
      {parseInt(count / 60000)}분 {(count % 60000) / 1000}초 남았습니다 (최근
      호출 시간 : {formatDate(getDateByTimeZone(fetchTime), "HH:mm:ss")}){" "}
      <SmallButton onClick={() => handlePause(!pause)} variant="warning">
        {pause || openedModal ? "시작" : "일시정지"}
      </SmallButton>
      <SmallButton onClick={fetch} variant="success">
        새로고침
      </SmallButton>
    </CounterCol>
  );
});

const SearchContainer = memo(() => {
  const { state, ...actions } = useDeliveriesMonthlyStore();
  const { closeModal, openModal } = useModalStore();

  const { handleSubmit, register, reset, setValue } = useForm();

  useEffect(() => {
    const initDate = new Date(`${state.query.month}-01`);

    reset({
      ...state.query,
      dateFrom: formatDate(initDate, "yyyy-MM-01"),
      dateTo: formatDate(lastDayOfMonth(initDate), "yyyy-MM-dd"),
    });
  }, []);

  const handleChange = useCallback((e) => {
    if (e.target.name === "month") {
      const newDate = new Date(`${e.target.value}-01`);

      setValue("month", e.target.value);
      setValue("dateFrom", formatDate(newDate, "yyyy-MM-01"));
      setValue("dateTo", formatDate(lastDayOfMonth(newDate), "yyyy-MM-dd"));
      // actions.setQuery({
      //   month: e.target.value,
      //   dateFrom: formatDate(newDate, "yyyy-MM-01"),
      //   dateTo: formatDate(lastDayOfMonth(newDate), "yyyy-MM-dd"),
      // });
    }
  }, []);

  const handleClear = useCallback(() => {
    const query = actions.resetQuery();
    reset({
      ...query,
    });
  }, [actions.resetQuery]);

  const handleSelectRider = useCallback(
    (rider) => {
      closeModal();
      actions.setQuery({
        rider,
      });
    },
    [actions.setQuery, closeModal],
  );

  const handleClickFindRider = useCallback(() => {
    openModal(
      <FindRiderModal onSelect={handleSelectRider} key="find-rider-modal" />,
    );
  }, [handleSelectRider, openModal]);

  const onSubmit = useCallback((data) => {
    actions.setQuery(data);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <ColFlex flex={4}>
          <Row>
            <ColFlex sm={1}>
              <Form.Group>
                <Form.Control as="select" custom name="types" ref={register}>
                  <option value="1;2;3">전체</option>
                  <option value="1">일반</option>
                  <option value="2;3">반품/픽업</option>
                </Form.Control>
              </Form.Group>
            </ColFlex>
            <ColFlex sm={1}>
              <Form.Group>
                <Form.Control as="select" custom name="dateType" ref={register}>
                  <option value={DeliveriesStore.QUERY_DATE_TYPE_RECEIPT_DATE}>
                    접수일자
                  </option>
                  <option
                    value={
                      DeliveriesStore.QUERY_DATE_TYPE_DELIVERY_ALLOCATION_DATE
                    }
                  >
                    배송배차일자
                  </option>
                  <option
                    value={
                      DeliveriesStore.QUERY_DATE_TYPE_PICKUP_COMPLETED_DATE
                    }
                  >
                    수거완료일자
                  </option>
                  <option
                    value={
                      DeliveriesStore.QUERY_DATE_TYPE_DELIVERY_COMPLETED_DATE
                    }
                  >
                    배송완료일자
                  </option>
                </Form.Control>
              </Form.Group>
            </ColFlex>
            <DatePickerCol sm={4}>
              <Form.Control
                name="month"
                type="month"
                ref={register}
                onChange={handleChange}
              />
              <Wave>
                ({state.query.dateFrom}~{state.query.dateTo})
              </Wave>
              <Form.Control
                id="start"
                name="dateFrom"
                type="date"
                ref={register}
                hidden
              />
              <Form.Control
                id="end"
                name="dateTo"
                type="date"
                ref={register}
                hidden
              />
              {/* <Wave>~</Wave>
              <Form.Control
                id="end"
                name="dateTo"
                type="date"
                ref={register}
                onChange={handleChange}
              /> */}
            </DatePickerCol>
          </Row>
        </ColFlex>
        <ColFlex>
          <Row>
            <FormLabel column sm={3}>
              라이더
            </FormLabel>
            <PaddingCol left={20}>
              <Form.Group>
                <Form.Control
                  readOnly
                  name="riderName"
                  value={state.query.rider && state.query.rider.name}
                />
              </Form.Group>
            </PaddingCol>
            <PaddingCol sm={3}>
              <Button variant="success" onClick={handleClickFindRider}>
                찾기
              </Button>
            </PaddingCol>
          </Row>
        </ColFlex>
      </FormRow>
      <FormRow>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              송하인명
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="senderName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              송하인연락처
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="senderMobile" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              출발동
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="senderDong" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              수거기사
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="pickupRiderName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              지역선택
            </FormLabel>
            <Col sm={8}>
              <Form.Control as="select" name="region" ref={register} custom>
                <option value="">전체</option>
                <option value="SEOUL">서울</option>
                <option value="GOYANG">고양</option>
                <option value="BUCHEON">부천</option>
                <option value="SEONGNAM">성남</option>
                <option value="HANAM">하남</option>
                <option value="GWANGMYEONG">광명</option>
                <option value="GWACHEON">과천</option>
                <option value="GUNPO">군포</option>
                <option value="SUWON">수원</option>
                <option value="ANSAN">안산</option>
                <option value="ANYANG">안양</option>
                <option value="UIWANG">의왕</option>
                <option value="YONGIN">용인</option>
                <option value="HWASEONG">화성</option>
                <option value="GIMPO">김포</option>
                <option value="GWANGJU">광주</option>
                <option value="INCHEON">인천</option>
                <option value="BUSAN">부산</option>
                <option value="GYUNGNAM_YANGSAN">경남양산</option>
                <option value="ULSAN">울산</option>
                <option value="GYUNGNAM_GIMHAE">경남김해</option>
                <option value="GWANGJU_GWANG">광주광역시</option>
              </Form.Control>
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              지역그룹
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="allocationGroupName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
      </FormRow>
      <FormRow>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              수하인명
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="receiverName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              수하인연락처
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="receiverMobile" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              도착동
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="receiverDong" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              도착도로명
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="receiverAddressRoad" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              배송기사
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="deliveryRiderName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              스캔번호
            </FormLabel>
            <Col sm={8}>
              <Form.Control
                type="number"
                name="orderingNumberByDeliveryRiderId"
                ref={register}
              />
            </Col>
          </Form.Group>
        </ColFlex>
      </FormRow>
      <FormRow>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              예약번호
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="bookId" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              사측주문번호
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="orderIdFromCorp" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              기업회원명
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="corpUserName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              접수점
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="spotName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex />
        <SubmitCol>
          <Button variant="danger" onClick={handleClear}>
            초기화
          </Button>{" "}
          <Button variant="success" type="submit">
            조회
          </Button>
        </SubmitCol>
      </FormRow>
    </Form>
  );
});

export default memo(() => {
  const { state, ...actions } = useDeliveriesMonthlyStore();
  const { ...deliveriesActions } = useDeliveriesStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { getValues, register, setValue } = useForm();

  const [loaded, setLoaded] = useState(false); // 첫 fetch 진행

  // TODO: 리팩토링 필요
  const handleChangeCheckBoxForm = useCallback(() => {
    let _queryTypes = getValues("queryTypes");

    const includedAll = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ALL,
    );
    const addedAll = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ALL,
    );

    // 전체를 선택한 경우
    // 전체 해제된 경우
    if (_queryTypes.length === 0 || (!includedAll && addedAll)) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_ALL];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    // 미배송
    const addedDelayed = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
    );
    const includedDelayed = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
    );

    if (addedDelayed && !includedDelayed) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedDelayed &&
      includedDelayed &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
      );
    }

    // 픽업 & 반품을 선택한 경우
    const addedTypePickup = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP,
    );
    const includedTypePickup = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP,
    );

    if (!includedTypePickup && addedTypePickup) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedTypePickup &&
      addedTypePickup &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP,
      );
    }

    const addedTypeReturn = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN,
    );
    const includedTypeReturn = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN,
    );

    if (!includedTypeReturn && addedTypeReturn) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedTypeReturn &&
      addedTypeReturn &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN,
      );
    }

    // 우편번호지역
    const addedCustomCustomerPostalCode = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE,
    );
    const includedCustomCustomerPostalCode = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE,
    );

    if (addedCustomCustomerPostalCode && !includedCustomCustomerPostalCode) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedCustomCustomerPostalCode &&
      includedCustomCustomerPostalCode &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE,
      );
    }

    // 취소 or 사고를 선택한 경우
    const addedAccident = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT,
    );
    const includedAccident = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT,
    );

    if (!includedAccident && addedAccident) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAccident &&
      addedAccident &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT,
      );
    }

    const addedCancel = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED,
    );
    const includedCancel = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED,
    );
    if (addedCancel && !includedCancel) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedCancel &&
      includedCancel &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED,
      );
    }

    // 송하인주소오류 / 송하인주소오류취소
    const addedSenderAddedAddressError = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
    );
    const includedSenderAddressError = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
    );
    if (addedSenderAddedAddressError && !includedSenderAddressError) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedSenderAddedAddressError &&
      includedSenderAddressError &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
      );
    }

    const addedSenderAddressErrorCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
    );
    const includedSenderAddressErrorCanceled = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
    );
    if (
      addedSenderAddressErrorCanceled &&
      !includedSenderAddressErrorCanceled
    ) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedSenderAddressErrorCanceled &&
      includedSenderAddressErrorCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
      );
    }

    // 수하인주소오류 / 수하인주소오류취소
    const addedReceiverAddressError = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
    );
    const includedReceiverAddressError = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
    );
    if (addedReceiverAddressError && !includedReceiverAddressError) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedReceiverAddressError &&
      includedReceiverAddressError &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
      );
    }

    const addedReceiverAddressErrorCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
    );
    const includedReceiverAddressErrorCanceled = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
    );
    if (
      addedReceiverAddressErrorCanceled &&
      !includedReceiverAddressErrorCanceled
    ) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedReceiverAddressErrorCanceled &&
      includedReceiverAddressErrorCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
      );
    }

    // 연락처오류 / 연락처오류취소
    const addedMobileError = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR,
    );
    const includedMobileError = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR,
    );
    if (addedMobileError && !includedMobileError) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedMobileError &&
      includedMobileError &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR,
      );
    }

    const addedMobileErrorCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED,
    );
    const includedMobileErrorCanceled = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED,
    );
    if (addedMobileErrorCanceled && !includedMobileErrorCanceled) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedMobileErrorCanceled &&
      includedMobileErrorCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED,
      );
    }

    // 배송 불가 지역을 선택한 경우
    const addedAddressNotSupported = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
    );
    const includedAddressNotSupported = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
    );

    if (!includedAddressNotSupported && addedAddressNotSupported) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAddressNotSupported &&
      addedAddressNotSupported &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
      );
    }

    const addedAddressNotSupportedCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
    );
    const includedAddressNotSupportedCanceled = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
    );

    if (
      !includedAddressNotSupportedCanceled &&
      addedAddressNotSupportedCanceled
    ) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAddressNotSupportedCanceled &&
      addedAddressNotSupportedCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
      );
    }

    // 배달 대행을 선택한 경우
    const addedAgencyAllocation = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
    );
    const includedAgencyAllocation = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
    );

    if (!includedAgencyAllocation && addedAgencyAllocation) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAgencyAllocation &&
      addedAgencyAllocation &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
      );
    }

    // 배달 대행 실패을 선택한 경우
    const addedAgencyAllocationFailed = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_FAILED,
    );
    const includedAgencyAllocationFailed = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_FAILED,
    );

    if (!includedAgencyAllocationFailed && addedAgencyAllocationFailed) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_FAILED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAgencyAllocationFailed &&
      addedAgencyAllocationFailed &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_FAILED,
      );
    }

    let queryTypes = _queryTypes;
    if (queryTypes.indexOf(DeliveriesStore.QUERY_DELIVERY_TYPE_ALL) > -1) {
      const index = _findIndex(
        queryTypes,
        (t) => t === DeliveriesStore.QUERY_DELIVERY_TYPE_ALL,
      );
      queryTypes = [
        ...queryTypes.slice(0, index),
        ...queryTypes.slice(index + 1),
      ];
    }
    queryTypes = queryTypes.filter((t) => !!t);

    setValue("queryTypes", queryTypes);
    actions.setQuery({
      queryTypes,
    });
  }, [actions.setTypesQuery, state.query.queryTypes]);

  const [fetchTime, setFetchTime] = useState();

  const fetch = useCallback(async () => {
    try {
      if (state.query) {
        const includedDelayed = state.query.queryTypes.includes(
          DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
        );

        if (!includedDelayed) {
          startLoading();

          await actions.fetchAll({ query: state.query });
          finishLoading();

          await actions.countByStatus({ query: state.query });

          setFetchTime(new Date());
        } else {
          fetchDelayed();
        }
      }

      setLoaded(true);
    } catch (e) {
      window.alert(
        `배송정보를 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.fetchAll, actions.countByStatus, state.query]);

  const fetchWithoutCounting = useCallback(async () => {
    try {
      if (state.query) {
        const includedDelayed = state.query.queryTypes.includes(
          DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
        );

        if (!includedDelayed) {
          startLoading();

          await actions.fetchAll({ query: state.query });
          finishLoading();

          setFetchTime(new Date());
        } else {
          fetchDelayed();
        }
      }

      setLoaded(true);
    } catch (e) {
      window.alert(
        `배송정보를 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.fetchAll, actions.countByStatus, state.query]);

  const fetchDelayed = useCallback(async () => {
    try {
      if (state.query) {
        startLoading();

        await actions.fetchAllDelayed({ query: state.query });
        finishLoading();

        await actions.countByStatus({ query: state.query });
      }
    } catch (e) {
      window.alert(
        `배송정보를 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.countByStatus, actions.fetchAllDelayed, state.query]);

  useEffect(() => {
    handleClear();
    setValue("queryTypes", state.query.queryTypes);

    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (loaded) {
      if (state.query.page > 1) {
        actions.setQuery({ page: 1 });
      } else {
        fetch();
      }
    }
  }, [
    state.query.allocationGroupName,
    state.query.bookId,
    state.query.corpUserName,
    state.query.dateType,
    state.query.dateFrom,
    state.query.dateTo,
    state.query.orderIdFromCorp,
    state.query.orderingNumberByDeliveryRiderId,
    state.query.receiverAddressRoad,
    state.query.receiverDong,
    state.query.receiverMobile,
    state.query.receiverName,
    state.query.region,
    state.query.rider,
    state.query.pickupRiderName,
    state.query.deliveryRiderName,
    state.query.senderDong,
    state.query.spotName,
    state.query.senderName,
    state.query.senderMobile,
    state.query.types,
  ]);

  useEffect(() => {
    if (loaded) {
      if (state.query.page > 1) {
        actions.setQuery({ page: 1 });
      } else {
        fetchWithoutCounting();
      }
    }
  }, [state.query.queryTypes]);

  useEffect(() => {
    if (loaded) {
      fetch();
    }
  }, [state.query.page, state.query.pageSize, state.query.orderBy]);

  useEffect(() => {
    if (loaded && state.reset) {
      fetch();
      actions.reset(false);
    }
  }, [state.reset]);

  const handleClear = useCallback(() => {
    actions.resetQuery();
  }, [actions.resetQuery]);

  const handleClickDownload = useCallback(async () => {
    try {
      startLoading();

      const _query = {
        ...state.query,
      };
      await deliveriesActions.fetchForDownload({ query: _query });
      window.alert(`다운로드 요청을 완료하였습니다.`);
    } catch (e) {
      window.alert(`목록다운에 실패하였습니다.\n에러메시지:${e.message}`);
    }

    finishLoading();
  }, [deliveriesActions.fetchForDownload, state.query]);

  const handleClickDownloadV2 = useCallback(async () => {
    try {
      startLoading();

      const _query = {
        ...state.query,
      };

      const {
        sheetUrl,
        totalCount,
        success,
      } = await deliveriesActions.fetchForDownloadV2({
        query: _query,
      });

      if (success) {
        if (
          window.confirm(
            `다운로드 링크(${sheetUrl})로 이동하시겠습니까? 총 건수: ${totalCount}`,
          )
        ) {
          window.open(sheetUrl);
        }
      } else {
        window.alert(`목록다운에 실패하였습니다.`);
      }
    } catch (e) {
      window.alert(`목록다운에 실패하였습니다.\n에러메시지:${e.message}`);
    }

    finishLoading();
  }, [deliveriesActions.fetchForDownloadV2, state.query]);

  const handleClickDownloadForBalanceAccount = useCallback(async () => {
    try {
      startLoading();

      const _query = {
        ...state.query,
        queryTypes: [DeliveriesStore.QUERY_BALANCE_ACCOUNT],
      };
      await deliveriesActions.fetchForDownload({ query: _query });
      window.alert(`정산 다운로드 요청을 완료하였습니다.`);
    } catch (e) {
      window.alert(`정산 다운로드에 실패하였습니다.\n에러메시지:${e.message}`);
    }

    finishLoading();
  }, [deliveriesActions.fetchForDownload, state.query]);

  const handleClickDownloadForBalanceAccountV2 = useCallback(async () => {
    try {
      startLoading();

      const _query = {
        ...state.query,
        queryTypes: [DeliveriesStore.QUERY_BALANCE_ACCOUNT],
      };

      const {
        sheetUrl,
        totalCount,
        success,
      } = await actions.fetchForDownloadV2({
        query: _query,
      });

      if (success) {
        if (
          window.confirm(
            `정산 다운로드 링크(${sheetUrl})로 이동하시겠습니까? 총 건수: ${totalCount}`,
          )
        ) {
          window.open(sheetUrl);
        }
      } else {
        window.alert(`정산 다운로드에 실패하였습니다.`);
      }
    } catch (e) {
      window.alert(`정산 다운로드에 실패하였습니다.\n에러메시지:${e.message}`);
    }

    finishLoading();
  }, [actions.fetchForDownload, state.query]);

  const handleAfterDetailUpdate = useCallback(() => {
    fetch();
  }, [fetch]);

  const handleClickHeader = useCallback(
    (orderBy) => {
      actions.setQuery({ orderBy });
    },
    [actions.setQuery],
  );

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const columns = useMemo(
    () => [
      {
        Header: "예약번호",
        id: "bookId",
        accessor: (row) => row.bookId,
        selectable: false,
        sortable: true,
        width: 140,
      },
      {
        Header: "접수일시",
        accessor: (row) => row.renderReceiptDate(),
        id: "RECEIPT_DATE",
        sortable: true,
        width: 150,
      },
      {
        Header: "최종상태(일시)",
        accessor: (row) => `${row.renderStatus()}(${row.renderLatestDate()})`,
        width: 200,
      },

      {
        Header: "접수점",
        accessor: (row) => row.spotName,
        id: "SPOT_NAME",
        sortable: true,
        width: 150,
      },
      {
        Header: "출발동",
        accessor: (row) => row.senderDongName,
        id: "SENDER_DONG",
        sortable: true,
        width: 100,
      },
      {
        Header: "송하인",
        accessor: (row) => row.senderName,
        id: "SENDER_NAME",
        sortable: true,
        width: 150,
      },
      {
        Header: "도착동",
        accessor: (row) => row.receiverDongName,
        id: "RECEIVER_DONG",
        sortable: true,
        width: 100,
      },

      {
        Header: "수거 담당",
        accessor: (row) => row.pickupRiderName,
        id: "PICKUP_RIDER_NAME",
        sortable: true,
        width: 100,
      },
      {
        Header: "배송 담당",
        accessor: (row) => row.deliveryRiderName,
        id: "DELIVERY_RIDER_NAME",
        sortable: true,
        width: 100,
      },
    ],
    [handleAfterDetailUpdate],
  );

  return (
    <Wrap>
      <Container>
        <Title>월별 목록</Title>

        <SearchContainer />
        <Row>
          <ButtonsCol>
            <Button onClick={handleClickDownload}>목록다운</Button>
            <Button onClick={handleClickDownloadV2}>목록다운V2</Button>
            <Button onClick={handleClickDownloadForBalanceAccount}>
              정산다운
            </Button>
            <Button onClick={handleClickDownloadForBalanceAccountV2}>
              정산다운V2
            </Button>
          </ButtonsCol>
        </Row>
        <CheckBoxRow>
          <Col>
            <Form onChange={handleChangeCheckBoxForm}>
              <Form.Check
                id="filter-all"
                inline
                label={`전체(${state.count.all})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_ALL}
                ref={register}
              />
              <Form.Check
                id="filter-receipted"
                inline
                label={`접수(${state.count.receipted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIPTED}
                ref={register}
              />
              <Form.Check
                id="filter-pickup-scheduled"
                inline
                label={`수거지정(${state.count.pickupScheduled})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_PICKUP_SCHEDULED}
                ref={register}
              />
              <Form.Check
                id="filter-pickup-completed"
                inline
                label={`수거완료(${state.count.pickupCompleted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_PICKUP_COMPLETED}
                ref={register}
              />
              <Form.Check
                id="filter-warehoused"
                inline
                label={`입고(${state.count.warehoused})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_WAREHOUSED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-allocated"
                inline
                label={`배송배차(${state.count.deliveryAllocated})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_ALLOCATED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-postponed"
                inline
                label={`배송연기(${state.count.postponed})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_POSTPONED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-started"
                inline
                label={`배송출고(${state.count.deliveryStarted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_STARTED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-completed"
                inline
                label={`배송완료(${state.count.deliveryCompleted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_COMPLETED}
                ref={register}
              />
              <Form.Check
                id="filter-accident"
                inline
                label={`사고(${state.count.accident})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT}
                ref={register}
              />
              <Form.Check
                id="filter-canceled"
                inline
                label={`취소(${state.count.canceled})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED}
                ref={register}
              />
              <Form.Check
                id="filter-typePickup"
                inline
                label={`픽업(${state.count.typePickup})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP}
                ref={register}
              />
              <Form.Check
                id="filter-typeReturn"
                inline
                label={`반품(${state.count.typeReturn})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN}
                ref={register}
              />
              <Form.Check
                id="filter-customCustomerPostalCode"
                inline
                label={`우편번호지역(${state.count.customCustomerPostalCode})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE
                }
                ref={register}
              />
              <Form.Check
                id="filter-senderAddressError"
                inline
                label={`송하인주소오류(${state.count.senderAddressError})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR}
                ref={register}
              />
              <Form.Check
                id="filter-senderAddressErrorCanceled"
                inline
                label={`송하인주소오류취소(${state.count.senderAddressErrorCanceled})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED
                }
                ref={register}
              />
              <Form.Check
                id="filter-receiverAddressError"
                inline
                label={`수하인주소오류(${state.count.receiverAddressError})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR
                }
                ref={register}
              />
              <Form.Check
                id="filter-receiverAddressErrorCanceled"
                inline
                label={`수하인주소오류취소(${state.count.receiverAddressErrorCanceled})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED
                }
                ref={register}
              />
              <Form.Check
                id="filter-deliverySentBack"
                inline
                label={`반송완료(${state.count.deliverySentBack})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_SENT_BACK}
                ref={register}
              />
              <Form.Check
                id="filter-deliveryLost"
                inline
                label={`분실완료(${state.count.deliveryLost})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_LOST}
                ref={register}
              />
              <Form.Check
                id="filter-mobileError"
                inline
                label={`연락처오류(${state.count.mobileError})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR}
                ref={register}
              />
              <Form.Check
                id="filter-mobileErrorCanceled"
                inline
                label={`연락처오류취소(${state.count.mobileErrorCanceled})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED
                }
                ref={register}
              />
              <Form.Check
                id="filter-delayedDeliveries"
                inline
                label={`미배송기록(${state.count.delayed})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED}
                ref={register}
              />
              <Form.Check
                id="filter-addressNotSupported"
                inline
                label={`배송불가지역(${state.count.addressNotSupported})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED
                }
                ref={register}
              />
              <Form.Check
                id="filter-addressNotSupportedCanceled"
                inline
                label={`배송불가지역취소(${state.count.addressNotSupportedCanceled})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED
                }
                ref={register}
              />
              <Form.Check
                id="filter-agencyAllocationKookmin"
                inline
                label={`배달접수(${state.count.agencyAllocationKookmin})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN
                }
                ref={register}
              />
              <Form.Check
                id="filter-agencyAllocationFailed"
                inline
                label={`배달접수실패(${state.count.agencyAllocationFailed})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_FAILED
                }
                ref={register}
              />
            </Form>
          </Col>
        </CheckBoxRow>
        <Row>
          <CounterContainer fetchTime={fetchTime} fetch={fetch} />
        </Row>
        <TableRow>
          <TableCol>
            <DeliveriesTable
              deliveries
              responsive
              bordered
              hover
              checkbox={false}
              columns={columns}
              currentPage={state.query.page}
              data={state.deliveries}
              goToPage={handleGoToPage}
              nextPage={handleNextPage}
              onClickHeader={handleClickHeader}
              pageCount={state.pageCount}
              pageSize={state.query.pageSize}
              previousPage={handlePreviousPage}
              setPageSize={handleSetPageSize}
            />
          </TableCol>
        </TableRow>
      </Container>
    </Wrap>
  );
});
