import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_TRANSLATE: "FETCH_TRANSLATE",
  SET_BODY: "SET_BODY",
};

export const PLATFORM = {
  DEEPL: "deepl",
  PAPAGO: "papago",
  OPENAI: "openai",
};

const INITIAL_STATE = {
  textTranslated: "",
  body: {
    text: "",
    platform: PLATFORM.DEEPL,
    temperature: null,
    topP: null,
    system: "",
    model: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_TRANSLATE:
      return {
        ...state,
        textTranslated: action.textTranslated,
      };
    case CONSTANTS.SET_BODY:
      return {
        ...state,
        body: {
          ...state.body,
          ...action.body,
        },
        textTranslated: "",
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useTranslateStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const translateText = useCallback(async () => {
    let body = {
      text: state.body.text,
      platform: state.body.platform,
    };

    if (state.body.temperature)
      body.temperature = Number(state.body.temperature);
    if (state.body.topP) body.topP = Number(state.body.topP);
    if (state.body.system) body.system = state.body.system;
    if (state.body.model) body.model = state.body.model;

    const { text } = await api.post("/etc/test/translate", body);

    dispatch({
      type: CONSTANTS.FETCH_TRANSLATE,
      textTranslated: text,
    });
  }, [api.post, state.body]);

  const setBody = useCallback(
    (body) => {
      dispatch({
        type: CONSTANTS.SET_BODY,
        body,
      });
    },
    [dispatch, state.body],
  );

  return {
    state,
    translateText,
    setBody,
  };
};
